import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Game} from 'src/app/util/game';
import {Match} from 'src/app/util/match';
import {MatchService} from 'src/app/services/match.service';
import {Profile} from 'src/app/util/profile';

// UI for displaying a list of games
// The list is gathered by the route resolver, so we know
// if we get here, we have a valid list of items (even if it's empty)
@Component({
  selector: 'game-list',
  templateUrl: './game-list.component.html',
  styleUrls: ['./game-list.component.scss']
})
export class GameListComponent implements OnInit, OnDestroy {
  public currentProfile!: Profile;
  public games!: Game[];
  private matches!: Match[];
  public matchCount: { [key: string]: number } = {};

  // let str = "OK" as keyof typeof status;

  // --------------------------------------------------------------------------
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private matchService: MatchService,
  ) {
    this.activatedRoute.data.subscribe(({currentProfile, games, matches}) => {
      this.currentProfile = currentProfile;
      this.games = games;
      this.matches = matches;

      this.games.forEach(game => {
        this.matchCount[game.id] = 0;
      })

      this.matches.forEach(match => {
        if (match != undefined && match.game != undefined) {
          this.matchCount[match.game]++;
        }
      })
    });
  }

  // --------------------------------------------------------------------------
  ngOnInit() {
    this.matchService.addListListener(this.onMatchListUpdated);
  }

  // --------------------------------------------------------------------------
  ngOnDestroy() {
    this.matchService.removeListListener(this.onMatchListUpdated);
  }

  // --------------------------------------------------------------------------
  new(game: any): void {
    this.router.navigate([`match/new/${game.id}`]);
  }

  // --------------------------------------------------------------------------
  view(game: any): void {
    this.matchService.setFilterCriteria('game', game.name);
    this.router.navigate(['match']);
  }

  // --------------------------------------------------------------------------
  private onMatchListUpdated = (matches: Match[]): void => {
    this.matches = matches;
    let count = 0
    this.matches.forEach(match => {
      if (match != undefined && match.game != undefined) {
        count++;
      }
      this.matchCount[match.game] = count;
    })
  }

}
