<facilitation-event-editor
  [header]="'New Facilitation Event'"
  [button-label]="'Create Event'"
  [current-profile]="currentProfile"
  [event]="event"
  [events]="events"
  [games]="games"
  [guest-accounts]="guestAccounts"
  [matches]="matches"
  [profiles]="profiles"
  (onApplyChanges)="create($event)"
></facilitation-event-editor>
