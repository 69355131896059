import {Component} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {AwsConfigService} from "../../services/aws-config.service";

// UI for navigating to different routes in the page (think high level bookmarks)
@Component({
  selector: 'site-navigation',
  templateUrl: './site-navigation.component.html',
  styleUrls: ['./site-navigation.component.scss']
})
export class SiteNavigationComponent {

  public tabs: MenuItem[] = [
    {label: 'Game Library', icon: 'pi pi-box', routerLink: '/game'},
  ]

  constructor(private configService: AwsConfigService) {
    if (configService.isECCODeployment()) {
      this.tabs = [
        {label: 'Game Library', icon: 'pi pi-box', routerLink: '/game'},
        {label: 'Your Matches', icon: 'pi pi-calendar', routerLink: '/match'}
      ]
    } else {
      // Todo: Figure out how to get amplify to use two different user pools.
      //  problem is, you can't manipulate the contents of the user pool that
      //  resides in gov cloud from services that reside in commercial cloud.
      this.tabs = [
        {label: 'Game Library', icon: 'pi pi-box', routerLink: '/game'},
        {label: 'Your Events & Matches', icon: 'pi pi-calendar', routerLink: '/match'}
      ]

    }

  }
}
