<div futuretech field class="add-player-header row full-width row-lines">
  <label class="role">Role</label>
  <label class="player-search">Player</label>
  <label class="clear">Clear</label>
</div>

<ng-container *ngFor="let player of players">
  <div futuretech field class="add-player row full-width row-lines">
    <p-dropdown class="role" [options]="player.availableRoles" [ngModel]="player.role" (ngModelChange)="onRoleChanged(player, $event)" optionLabel="displayName" optionValue="gameRole" appendTo="body"></p-dropdown>
    <p-autoComplete class="player-search" [(ngModel)]="player.profile" (ngModelChange)="updateRoleUserMapping()" [suggestions]="potentialUsers" (completeMethod)="filterUsers($event.query)" [completeOnFocus]="true" [forceSelection]="true" [showEmptyMessage]="true" field="displayName" dataKey="sub" appendTo="body"></p-autoComplete>
    <button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-text clear" (click)="clear(player)"></button>
  </div>
</ng-container>
