import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ProfileService } from 'src/app/services/profile.service';
import { Profile } from 'src/app/util/profile';
import {AwsConfigService} from "../../services/aws-config.service";

// UI for editing a particular profile
// The profile being edited is gathered from a route resolver, so we
// know the profile exists if we got here
@Component({
  selector: 'profile-editor',
  templateUrl: './profile-editor.component.html',
  styleUrls: ['./profile-editor.component.scss']
})
export class ProfileEditorComponent {
  public profile!: Profile;
  public displayPasswordDialog: boolean = false;
  public displayEmailDialog: boolean = false;
  public newEmail?: string;
  public oldPassword: string = "";
  public newPassword: string = "";
  public confirmPassword: string = "";
  public confirmationCode?: string;
  public verificationCodeSent: boolean = false;
  public strongRegex: string = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#&$%*_])(?=.{8,})";
  public authError?: string; //any authentication error string to be displayed

  // --------------------------------------------------------------------------
  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private loadingService: LoadingService,
    private profileService: ProfileService,
    private authenticationService: AuthenticationService,
    private configService: AwsConfigService
  ) {
    this.route.data.subscribe(({ currentProfile }) => {
      this.profile = currentProfile;
    });
  }

  // --------------------------------------------------------------------------
  isEccoDeployment() {
    return this.configService.isECCODeployment()
  }

  // --------------------------------------------------------------------------
  showEmailDialog(show: boolean) {
    this.displayEmailDialog = show;
    this.verificationCodeSent = false;
    this.newEmail = "";
  }

  // --------------------------------------------------------------------------
  showPasswordDialog(show: boolean) {
    this.displayPasswordDialog = show;
    this.oldPassword = "";
    this.newPassword = "";
    this.confirmPassword = "";
    this.authError = "";
  }

  // --------------------------------------------------------------------------
  //Send the updated email address to server for changing. Note, requires a separate
  //verification code.
  submitEmailChange() {
    this.verificationCodeSent = true;

    this.profileService.updateUsingAmplify({ email: this.profile.email || '', preferred_username: this.profile.email || '' }).subscribe((response) => {
      console.log("email change submitted:", response);
    });
  }

  // --------------------------------------------------------------------------
  //Send any non-verifiable Profile attribute changes to the server.
  save(): void {
    this.loadingService.loading = true;

    this.profileService.update(this.profile).subscribe((response) => {
      console.log("updated profile:", response);
      this.location.back();
    });
  }

  // --------------------------------------------------------------------------
  //Send the email changed verification code to the server to verify the new
  //email address.
  submitEmailVerificationCode(): void {
    if (!!this.confirmationCode) {
      this.loadingService.loading = true;
      this.profileService.verifyAttributeWithCode('email', this.confirmationCode).subscribe({
        next: (response) => {
          console.log("email address updated:", response);
          this.location.back();
        },
        error: () => {
          this.loadingService.loading = false;
        }
      });
    }
    else {
      this.messageService.add({
        severity: 'error',
        detail: 'No confirmation code has been entered. Can not send verification.',
        sticky: true
      });
    }
  }

  // --------------------------------------------------------------------------
  cancel(): void {
    this.location.back();
  }

  // --------------------------------------------------------------------------
  //Attempt to change the user's password from the oldPassword to the newPassword
  changePassword(): void {
    this.authenticationService.changePassword(this.newPassword, this.oldPassword).subscribe({
      next: (response) => {
        this.authError = "";
        this.location.back();
      },
      error: (error) => {
        if (error)
        this.authError = error.message;
      }
    });
  }
}
