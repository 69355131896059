import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private authService: AuthenticationService;

  // --------------------------------------------------------------------------
  constructor(private authenticationService: AuthenticationService){
    this.authService = authenticationService;
  }

  // --------------------------------------------------------------------------
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.getIdentityToken();

    // If we have one, then add it on to our request header
    if (token) {
      const cloned = req.clone({
        headers: req.headers.set("Authorization", token)
      });
      return next.handle(cloned);
    }

    // Otherwise, just continue with the request
    return next.handle(req);
  }
}