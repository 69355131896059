import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { MessageService } from 'primeng/api';
import { map, Observable } from 'rxjs';
import { MatchService } from '../services/match.service';
import { Match } from '../util/match';

// Route resolver for getting a match using the ID that's part of the route
@Injectable({
  providedIn: 'root'
})
export class MatchResolver implements Resolve<Match | null> {
  // --------------------------------------------------------------------------
  constructor(
    private router: Router,
    private messageService: MessageService,
    private matchService: MatchService
  ) { }

  // --------------------------------------------------------------------------
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Match | null> {
    let matchId = route.paramMap.get('id') || '';
    return this.matchService.get(matchId).pipe(
      map(match => {
        if (!!match) {
          return match;
        }
        this.messageService.add({
          severity: 'error', 
          detail: `Could not find match for id ${matchId}`,
          sticky: true
        });
        this.router.navigate(['/match']);
        return null;
      })
    );
  }
}
