import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GameRole } from 'src/app/util/game';
import { Match, RoleUserMapping } from 'src/app/util/match';
import { Profile } from 'src/app/util/profile';

type Player = {
  role: string,
  displayName: string,
  userId: string,
  type?: string,
  selected: boolean
};

// UI for getting the information needed for logging in a registered user
@Component({
  selector: 'joined-player',
  templateUrl: './joined-player.component.html',
  styleUrls: ['./joined-player.component.scss']
})
export class JoinedPlayerComponent {
  @Input() match!: Match;
  @Input('current-profile') currentProfile?: Profile;
  @Input() set profiles(profiles: Profile[]) {
      this._profiles = profiles;
      // If we already have players, then update them with the incoming profiles
      if (!!this.players) {
        this.players.forEach(player => {
          let profile = profiles.find(profile => profile.sub == this._playerMappings[player.role]);
          if (!!profile) {
            player.displayName = profile.displayName || 'Unknown User';
          }
        });
      }
    };
    get profiles(): Profile[] { return this._profiles; }
  @Input() roles!: GameRole[];
  @Input('available-roles') availableRoles!: GameRole[];
  @Input('player-mappings') set playerMappings (playerMappings: RoleUserMapping) {
    this._playerMappings = playerMappings;
    this.players = [];
    for (var gameRole in playerMappings) {
      let userID = playerMappings[gameRole];
      if (!!userID) {
        let profile = this.profiles.find(profile => profile.sub == userID);
        if (!!profile) {
          this.players.push({
            role: this.roles.find(role=> role.gameRole == gameRole)?.displayName || gameRole,
            displayName: profile.displayName || 'Unknown User',
            userId: profile.sub,
            type: profile.type || '???',
            selected: false
          });
        }
      }
    }
    this.openSlots = this.match.playerCount - this.players.length;
    this.alreadyJoined = Object.values(playerMappings).includes(this.currentProfile?.sub);
    this.canJoinMatch = this.openSlots > 0 && !this.alreadyJoined;
  }
  @Output() onJoin: EventEmitter<string> = new EventEmitter();
  @Output() onLeave: EventEmitter<void> = new EventEmitter();

  public players!: Player[];
  public openSlots!: number;
  public canJoinMatch: boolean = false;
  public joiningRole!: string;
  public alreadyJoined: boolean = false;
  private _profiles!: Profile[];
  private _playerMappings!: RoleUserMapping;

  // --------------------------------------------------------------------------
  join(): void {
    this.onJoin.emit(this.joiningRole);
  }

  // --------------------------------------------------------------------------
  leave(): void {
    this.onLeave.emit();
  }
}
