<div class="add-guest-user ">
  <div class="content margin-3">
    <div futuretech field class="inputText column full-width text-center">
      <label for="displayName">Display Name</label>
      <input pInputText type="text" name="displayName" id="displayName" [(ngModel)]="account.displayName" />
    </div>
    <div futuretech field class="inputText column full-width text-center">
      <label for="playereml">Email (Optional)</label>
      <input pInputText type="text" name="playereml"  id="playereml" [placeholder]="placeholder" [(ngModel)]="account.email"/>
    </div>
  </div>
  <div class="content-footer">
    <p-button class="margin-1" (onClick)="createGuestUser()" [disabled]="!isValidDisplayName(account.displayName)">{{editUpdateButtonText}}</p-button>
    <p-button class="margin-1" (onClick)="cancel()">Cancel</p-button>
  </div>
</div>
