<!-- The routed views render in the <router-outlet>-->
<amplify-authenticator [services]="services"
                       [loginMechanisms]="['username']"
                       [formFields]="formFields"
                       [hideSignUp] = true
                       class="p-component"
>
  <ng-template amplifySlot="authenticated" let-user="user" let-signOut="signOut">
    <router-outlet name="header"></router-outlet>
    <router-outlet name="current-profile"></router-outlet>
    <router-outlet name="footer"></router-outlet>
    <router-outlet></router-outlet>
    <loading [active]="loadingService.loading"></loading>
  </ng-template>
</amplify-authenticator>
<p-toast position="bottom-center" life="2000"></p-toast>
<div id="version-number">v 1.0</div>
