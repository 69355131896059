<div class="p-component detail-root">
  <div class="standard-container detail-container">
    <div class="content-header">{{header}}</div>
    <form action="">
      <div class="content">
        <div class="top-section">
          <div futuretech field class="inputText row full-width">
            <label for="eventname">Event Name</label>
            <input pInputText type="text" name="eventname" id="eventname" [(ngModel)]="event.name" (ngModelChange)="onEventNameChanged()"/>
          </div>
          <div futuretech field class="inputText row full-width">
            <label for="description">Event Description</label>
            <input pInputText type="text" id="description" name="description" [(ngModel)]="event.description" (ngModelChange)="onEventDescriptionChanged()"/>
          </div>
        </div>
        <div class="bottom-left">
          <guest-user-list [current-profile]="currentProfile" [event]="event" [matches]="matches" [(value)]="guestAccounts" (valueChange)="onGuestAccountsChanged()"></guest-user-list>
        </div>
        <div class="bottom-right">
          <mini-match-list [games]="games" [event]="event" [profiles]="potentialProfiles" [(value)]="matches" (valueChange)="onMatchesChanged()"></mini-match-list>
        </div>
      </div>
      <div class="content-footer">
        <p-fieldset futuretech>
          <p-button (onClick)="create()" [disabled]="!canCreate()">{{buttonLabel}}</p-button>
        </p-fieldset>
      </div>
      <div class="content-message"><span class="disabled-reason" *ngIf="disabledReason"><i class="pi pi-exclamation-triangle margin-2-horizontal"></i>{{disabledReason}}<i class="pi pi-exclamation-triangle margin-2-horizontal"></i></span></div>
    </form>
  </div>
</div>
