import { Injectable } from '@angular/core';
import { Observable, of, switchMap, tap } from 'rxjs';

import { AuthenticationService } from './authentication.service';
import { HTTPService } from './http.service';
import { MatchService } from './match.service';
import { ProfileService } from './profile.service';
import { WebsocketService } from './websocket.service';

import { FacilitationEvent } from '../util/facilitation-event';
import { Profile } from '../util/profile';
import { Match } from '../util/match';

// Service for gathering FacilitationEvents from the server (both a full list and individually)
// UI components can register to listen for updates from websockets
@Injectable({
  providedIn: 'root'
})
export class FacilitationEventService {
  private listListeners: Function[] = [];
  private cachedEvents?: FacilitationEvent[];
  private allEventNameMap: { [id: string]: string } = {};

  // --------------------------------------------------------------------------
  constructor(
    private httpService: HTTPService,
    private authenticationService: AuthenticationService,
    private matchService: MatchService,
    private profileService: ProfileService,
    private websocketService: WebsocketService
  ) {
    this.websocketService.on('event-create', this.onEventCreated);
    this.websocketService.on('event-update', this.onEventUpdated);
    this.websocketService.on('event-delete', this.onEventDeleted);
    this.websocketService.on('match-create', this.onMatchCreated);
    this.websocketService.on('match-delete', this.onMatchDeleted);
  }

  // --------------------------------------------------------------------------
  addListListener(cb: Function): void {
    this.listListeners.push(cb);
  }

  // --------------------------------------------------------------------------
  removeListListener(cb: Function): void {
    this.listListeners = this.listListeners.filter(listener => listener != cb);
  }

  // --------------------------------------------------------------------------
  clearCache(): void {
    delete this.cachedEvents;
  }

  // --------------------------------------------------------------------------
  getList(): Observable<FacilitationEvent[]> {
    if (!!this.cachedEvents) {
      return of(this.cachedEvents);
    }

    return this.httpService.get('event').pipe(
      switchMap((result: { filteredEventList: any[], allEventNameMap: { [id: string]: string } }) => {
        this.allEventNameMap = result.allEventNameMap;
        return of(result.filteredEventList.map(event => this.serverToEventObj(event)))
      }),
      tap((events: FacilitationEvent[]) => this.cachedEvents = events)
    );
  }

  // --------------------------------------------------------------------------
  get(eventId: string): Observable<FacilitationEvent | undefined> {
    if (!!this.cachedEvents) {
      return of(this.cachedEvents.find(event => event.id == eventId));
    }

    return this.httpService.get(`event`, { eid: eventId }).pipe(
      switchMap((result: any) => of(this.serverToEventObj(result)))
    );
  }

  // --------------------------------------------------------------------------
  create(params: FacilitationEvent): Observable<FacilitationEvent> {
    return this.httpService.post(`event`, params);
  }

  // --------------------------------------------------------------------------
  update(params: FacilitationEvent): Observable<any> {
    return this.httpService.put(`event`, params);
  }

  // --------------------------------------------------------------------------
  delete(params: FacilitationEvent): Observable<any> {
    return this.httpService.delete('event', params.id);
  }

  // --------------------------------------------------------------------------
  isUniqueName(event: FacilitationEvent): boolean {
    for (let id in this.allEventNameMap) {
      // If this event's name is in the list for another event, it is not unique
      if (id != event.id && this.allEventNameMap[id] == event.name) {
        return false;
      }
    }
    // This event's name is not in our list anywhere
    return true;
  }

  // --------------------------------------------------------------------------
  // The WebSocket has gotten a signal that an event has been created
  private onEventCreated = (event: any): void => {
    this.authenticationService.getCurrentUserId().pipe(
      switchMap((currentUserId?: string) => !!currentUserId ? this.profileService.get(currentUserId) : of(undefined)),
      switchMap((currentUser?: Profile) => this.matchService.getList().pipe(
        switchMap((matches: Match[]) => of({ currentUser: currentUser, matches: matches}))
      ))
    ).subscribe((params: { currentUser?: Profile, matches: Match[] }) => {
      if (!!this.cachedEvents && !!params.currentUser) {
        // Set whether we are the creator of this match or not
        event.isCreator = event.creator == params.currentUser.sub;
        if (this.canUserSeeEvent(event, params.currentUser, params.matches)) {
          this.cachedEvents.push(this.serverToEventObj(event));
        }

        // Add the event name to our list of all event names
        this.allEventNameMap[event.id] = event.name;

        this.updateListListeners();
      }
    });
  }

  // --------------------------------------------------------------------------
  // The WebSocket has gotten a signal that an event has been updated
  private onEventUpdated = (event: any): void => {
    if (!!this.cachedEvents) {
      let existingEvent: any = this.cachedEvents?.find(cachedEvent => cachedEvent.id == event.id);
      if (!!existingEvent) {
        // Update any attributes from the incoming event (it won't have ALL the event properties on it)
        for (let key in event) {
          if (existingEvent.hasOwnProperty(key)) {
            existingEvent[key] = event[key];
          }
        }
      }

      // Update the event name in our list of all event names
      this.allEventNameMap[event.id] = event.name;

      this.updateListListeners();
    }
  }

  // --------------------------------------------------------------------------
  // The WebSocket has gotten a signal that an event has been deleted
  private onEventDeleted = (event: any): void => {
    if (!!this.cachedEvents) {
      this.cachedEvents = this.cachedEvents.filter(existingEvent => existingEvent.id != event.id);
      this.cachedEvents = this.cachedEvents.filter(existingEvent => existingEvent.id != event.id);

      // Delete the event name in our list of all event names
      delete this.allEventNameMap[event.id];

      this.updateListListeners();
    }
  }

  // --------------------------------------------------------------------------
  // The WebSocket has gotten a signal that a match has been created
  private onMatchCreated = (match: any): void => {
    // If this match is part of an event we don't know about and if the match
    // is one we should see, then get our full event list from the server
    if (!!match.event && !!this.cachedEvents && !this.cachedEvents.some(event => event.id == match.event)) {
      this.authenticationService.getCurrentUserId().pipe(
        switchMap((currentUserId?: string) => !!currentUserId ? this.profileService.get(currentUserId) : of(undefined)),
      )
      .subscribe((currentUser?: Profile) => {
        if (!!currentUser) {
          // Set whether we are the creator of this match or not
          match.isCreator = match.creator == currentUser.sub;
          if (this.matchService.canUserSeeMatch(match, currentUser)) {
            this.clearCache();
            this.getList().subscribe(() => this.updateListListeners());
          }
        }
      });
    }
  }

  // --------------------------------------------------------------------------
  // The WebSocket has gotten a signal that a match has been deleted
  private onMatchDeleted = (match: any): void => {
    // If this match is part of an event we know about, make sure that event is
    // still one we care about without that match
    if (!!match.event && !!this.cachedEvents) {
      let event = this.cachedEvents.find(event => event.id == match.event);
      if (!!event) {
        this.authenticationService.getCurrentUserId().pipe(
          switchMap((currentUserId?: string) => !!currentUserId ? this.profileService.get(currentUserId) : of(undefined)),
        )
        .subscribe((currentUser?: Profile) => {
          // This check only applies if the user is not a guest account, didn't create the event, and the event doesn't have other matches assigned to the user
          if (!!event && !!currentUser && !currentUser.isGuest() && !event.isCreator && !this.matchService.doesUserHaveEventMatches(currentUser, event, match)) {
            this.onEventDeleted(event);
          }
        });
      }
    }
  }

  // --------------------------------------------------------------------------
  private updateListListeners(): void {
    this.listListeners.forEach(listener => listener(this.cachedEvents));
  }

  // --------------------------------------------------------------------------
  private serverToEventObj(serverObj: any): FacilitationEvent {
    return FacilitationEvent.clone(serverObj);
  }

  // --------------------------------------------------------------------------
  private canUserSeeEvent(event: FacilitationEvent, user: Profile, matches: Match[]): boolean {
    // Users can only see events that this user created, the user is associated with, or has matches this user is assigned to
    return event.isCreator || event.id == user.event ||
      matches.some(match => match.event == event.id && Object.values(match.roleUserMapping).includes(user.sub));
  }
}
