import {Injectable} from '@angular/core';
import {from, Observable, of, switchMap, tap} from 'rxjs';
import {Auth} from 'aws-amplify';

import {HTTPService} from './http.service';

import {Profile, ProfileType} from '../util/profile';
import {AuthenticationService} from './authentication.service';
import {FacilitationEvent} from '../util/facilitation-event';
import {WebsocketService} from './websocket.service';

export const UPDATE_PROFILES_SIGNAL: string = 'update-profiles';

// Service for gathering Profiles from the server (both a full list and individually)
@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private profilesById = new Map<string, Profile>();///A cache of the list of all user Profiles
  private listListeners: Function[] = []; // Functions to report when our profile list gets updated by websockets

  // --------------------------------------------------------------------------
  constructor(
    private httpService: HTTPService,
    private websocketService: WebsocketService,
    private authenticationService: AuthenticationService
  ) {
    this.websocketService.on(UPDATE_PROFILES_SIGNAL, this.onUpdateProfileList);
  }

  // --------------------------------------------------------------------------
  addListListener(cb: Function): void {
    this.listListeners.push(cb);
  }

  // --------------------------------------------------------------------------
  removeListListener(cb: Function): void {
    this.listListeners = this.listListeners.filter(listener => listener != cb);
  }

  // --------------------------------------------------------------------------
  clearCache(): void {
    this.profilesById.clear();
  }

  // --------------------------------------------------------------------------
  // Get an array of all existing Profiles (including the logged in user's).
  getList(): Observable<Profile[]> {
    if (this.profilesById.size > 0) {
      return of(this.profileMapToList());
    }

    return this.fetchList();
  }

  // --------------------------------------------------------------------------
  //Get a Profile instance using a userID
  get(userID: string): Observable<Profile | undefined> {
    if (this.profilesById.has(userID)) {
      return of(this.profilesById.get(userID));
    }

    return this.fetchList().pipe(
      switchMap(() => {
        return of(this.profilesById.get(userID));
      })
    );
  }

  // This is a temporary replacement for `updateUsingAmplify`
  // it only handles display name changes in the new dynamodb-based profile system.
  update(profile: Profile): Observable<any> {
    console.log(JSON.stringify(profile,null,1))
    return this.httpService.put(`profile/${profile.sub}`, profile)
      .pipe(
        tap(_ => this.broadcastProfileUpdate())
      )

  }


  // --------------------------------------------------------------------------
  //Update the currently logged in user's profile using the supplied changed attributes
  // todo: this only works if the user pool is in the same partition as the game system.
  //  replace with actions on the api backend.
  updateUsingAmplify(changedProfileAttributes: { [key: string]: string }): Observable<any> {
    return from(Auth.currentAuthenticatedUser()
      .then((user) => Auth.updateUserAttributes(user, changedProfileAttributes))
      .then((result) => {
        // If attributes changed, wipe out current cache
        this.profilesById.clear();

        // Broadcast to everyone that the profile list needs to be update
        this.broadcastProfileUpdate();

        return result;
      })
    );
  }

  // --------------------------------------------------------------------------
  //Verify the supplied changed attribute name with the supplied code.
  verifyAttributeWithCode(changedAttributeName: string, code: string): Observable<any> {
    return from(Auth.verifyCurrentUserAttributeSubmit(changedAttributeName, code));
  };

  // --------------------------------------------------------------------------
  createGuestAccount(event: FacilitationEvent, account: Profile, broadcast: Boolean = true): Observable<any> {
    account.event = event.id;
    return this.httpService.post('profile', {event: event, account: account}).pipe(
      switchMap(response => of(this.serverObjToProfile(response))),
      tap(_ => {
        if (broadcast) {
          this.broadcastProfileUpdate()
        }
      })
    );
  }

  // --------------------------------------------------------------------------
  deleteGuestAccount(event: FacilitationEvent, account: Profile): Observable<any> {
    return this.httpService.delete('profile', {event: event, account: account}).pipe(
      tap(_ => this.broadcastProfileUpdate())
    );
  }


  // --------------------------------------------------------------------------
  updateGuestAccountName(originalEvent: FacilitationEvent, newEvent: FacilitationEvent, originalAccount: Profile, newAccount: Profile): Observable<any> {
    return this.httpService.patch('profile', {
      originalEvent: originalEvent,
      newEvent: newEvent,
      originalAccount: originalAccount,
      newAccount: newAccount,
      attribute: 'name'
    }).pipe(
      tap(_ => this.broadcastProfileUpdate())
    );
  }

  // --------------------------------------------------------------------------
  updateGuestAccountEmail(event: FacilitationEvent, originalAccount: Profile, newAccount: Profile): Observable<any> {
    return this.httpService.patch('profile', {
      originalEvent: event,
      originalAccount: originalAccount,
      newAccount: newAccount,
      attribute: 'email'
    }).pipe(
      tap(_ => this.broadcastProfileUpdate())
    );
  }

  // --------------------------------------------------------------------------
  broadcastProfileUpdate(): void {
    this.websocketService.broadcast(UPDATE_PROFILES_SIGNAL);
  }

  // --------------------------------------------------------------------------
  private profileMapToList(): Profile[] {
    return Array.from(this.profilesById.values());
  }

  // --------------------------------------------------------------------------
  // http://<url>/profile
  private fetchList(): Observable<Profile[]> {
    return this.httpService.get('profile')
      .pipe(
        switchMap(profiles => {
          tap(p => console.log(p))
          return this.authenticationService.getCurrentUserId().pipe(
            switchMap(currentUserId => {
              // Clear out any previous profiles we had
              this.profilesById.clear();

              //Create a real Profile instance
              profiles.forEach((user: any) => {
                this.profilesById.set(user.sub, this.serverObjToProfile(user, currentUserId));
              });
              return of(this.profileMapToList());
            })
          )
        })
      );
  }

  // --------------------------------------------------------------------------
  // The WebSocket has gotten a signal to update the profile list
  private onUpdateProfileList = (params: string): void => {
    this.fetchList().subscribe(() => {
      this.listListeners.forEach(listener => listener(this.profileMapToList()));
    });
  }

  // --------------------------------------------------------------------------
  /* server object shape
  {
    "groups": [
      "ge-admin",
      "game-tester",
      "ge-members",
      "game-facilitator",
      "player"
    ],
    "objectType": "USER",
    "nickname": "kastork",
    "email": "kastork@nps.edu",
    "id": "7cf15d4d-db55-412d-b983-db1217f91803",
    "sub": "7cf15d4d-db55-412d-b983-db1217f91803"
  }
   */
  private serverObjToProfile(serverObj: any, currentUserId?: string): Profile {
    let profile = new Profile();
    profile.displayName = serverObj.nickname;
    profile.email = serverObj.email;
    profile.sub = serverObj.sub;
    profile.event = serverObj.event;
    profile.groups = serverObj.groups;
    if (profile.sub == currentUserId) {
      profile.type = ProfileType.You;
    }
    // If this profile has an event id associated with it, it's a guest account
    else if (!!profile.event) {
      profile.type = ProfileType.Guest;
    }
    return profile;
  }
}
