import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FacilitationEventService } from 'src/app/services/facilitation-event.service';
import { MatchService } from 'src/app/services/match.service';
import { ProfileService } from 'src/app/services/profile.service';
import { Profile } from 'src/app/util/profile';

// UI for displaying some basic profile information for the currently logged in user
// This component should only be displayed when we know for a fact that a user is logged in
@Component({
  selector: 'current-profile',
  templateUrl: './current-profile.component.html',
  styleUrls: ['./current-profile.component.scss']
})
export class CurrentProfileComponent {
  public currentProfile!: Profile;

  // --------------------------------------------------------------------------
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private facilitationEventService: FacilitationEventService,
    private matchService: MatchService,
    private profileService: ProfileService
  ) {
    this.route.data.subscribe(({ currentProfile }) => {
      this.currentProfile = currentProfile;
    });
  }

  // --------------------------------------------------------------------------
  edit(): void {
    if (!!this.currentProfile) {
      this.router.navigate([`/profile`]);
    }
  }

  // --------------------------------------------------------------------------
  logout(): void {
    // Clear the cached events and matches since they were specific to
    // the previously logged in user
    this.facilitationEventService.clearCache();
    this.matchService.clearCache();
    this.profileService.clearCache();

    this.authenticationService.logout().subscribe(() => {
      this.authenticationService.setReturnUrl(this.router.routerState.snapshot.url);
    });
  }
}
