import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { concat, Observable, of, switchMap } from 'rxjs';
import { FacilitationEventService } from 'src/app/services/facilitation-event.service';
import { LoadingService } from 'src/app/services/loading.service';
import { MatchService } from 'src/app/services/match.service';
import { ProfileService } from 'src/app/services/profile.service';
import { FacilitationEvent } from 'src/app/util/facilitation-event';
import { Game } from 'src/app/util/game';
import { Match } from 'src/app/util/match';
import { Profile } from 'src/app/util/profile';
import { FacilitationEventEditorOutput } from '../facilitation-event-editor/facilitation-event-editor.component';

// UI for displaying the details for one particular facilitation event
// The event in question is gathered from the route resolver, so we know
// it exists if we got here
@Component({
  selector: 'facilitation-event-detail',
  templateUrl: './facilitation-event-detail.component.html',
  styleUrls: ['./facilitation-event-detail.component.scss']
})
export class FacilitationEventDetailComponent {
  public currentProfile!: Profile;
  public event!: FacilitationEvent;
  public events!: FacilitationEvent[];
  public games!: Game[];
  public guestAccounts!: Profile[];
  public matches!: Match[];
  public profiles!: Profile[];
  private originalEvent!: FacilitationEvent;
  private originalGuestAccounts!: Profile[];
  private originalMatches!: Match[];

  // --------------------------------------------------------------------------
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private facilitationEventService: FacilitationEventService,
    private matchService: MatchService,
    private profileService: ProfileService,
    private loadingService: LoadingService,
  ) {
    this.route.data.subscribe(({ currentProfile, event, events, games, matches, profiles }) => {
      this.currentProfile = currentProfile;
      this.originalEvent = event;
      this.event = FacilitationEvent.clone(event);
      this.events = events;
      this.games = games;
      this.originalMatches = matches.filter((match: Match) => match.event == this.event.id);
      this.matches = this.originalMatches.map(match => Match.clone(match));
      this.originalGuestAccounts = profiles.filter((profile: Profile) => profile.event == this.event.id);
      this.guestAccounts = this.originalGuestAccounts.map(account => Profile.clone(account));
      this.profiles = profiles.filter((profile: Profile) => !profile.event);
    });
  }

  // --------------------------------------------------------------------------
  update(parameters: FacilitationEventEditorOutput): void {
    this.loadingService.loading = true;

    let event = parameters.event;
    let guestAccounts = parameters.guestAccounts;
    let matches = parameters.matches;

    // Get our list of operations for guest accounts and matches
    let guestAccountOperations = this.createGuestAccountEditOperations(event, guestAccounts, matches);
    let matchOperations = this.createMatchEditOperations(event, matches);

    // If the event details changed, then add an operation to update them as well
    let observableOperations = [...guestAccountOperations, ...matchOperations];
    if (event.name != this.originalEvent.name || event.description != this.originalEvent.description) {
      observableOperations.push(this.facilitationEventService.update(event));
    }

    // If we have any operations to perform, then perform them
    if (observableOperations.length > 0) {
      concat(...observableOperations).subscribe({
        complete: () => {
          if (guestAccountOperations.length > 0) {
            // Make sure our profile list gets updated if we made any changes to them
            this.profileService.broadcastProfileUpdate();
          }

          this.router.navigateByUrl('/match');
        },
        error: () => {
          this.loadingService.loading = false;
        }
      })
    }
  }

  // --------------------------------------------------------------------------
  private createGuestAccountEditOperations(event: FacilitationEvent, guestAccounts: Profile[], matches: Match[]): Observable<any>[] {
    let newGuestAccounts: Profile[] = [];
    let updatedNameGuestAccounts: { old: Profile, new: Profile }[] = [];
    let updatedEmailGuestAccounts: { old: Profile, new: Profile }[] = [];
    guestAccounts.forEach(account => {
      // If the account doesn't have an id, then it's been added just now
      if (!account.event) {
        newGuestAccounts.push(account);
      }
      else {
        // Otherwise, check if the display name or email changed since we have to handle those differently
        let existingAccount = this.originalGuestAccounts.find(originalAccount => originalAccount.sub == account.sub);
        if (!!existingAccount) {
          // If the event name changed or the account's display name changed, then we have to update the secondary usernames
          // of the guest accounts since its username is based on the event and display name
          if (event.name != this.originalEvent.name || account.displayName != existingAccount.displayName) {
            updatedNameGuestAccounts.push({ old: existingAccount, new: account });
          }
          if (account.email != existingAccount.email) {
            updatedEmailGuestAccounts.push({ old: existingAccount, new: account });
          }
        }
      }
    });
    // Get rid of any account that were in our original list, but aren't in our new list
    let deletedGuestAccounts = this.originalGuestAccounts.filter(originalAccount => guestAccounts.every(account => account.sub != originalAccount.sub));

    let observableOperations: Observable<any>[] = [];

    // Delete guest accounts, if any were removed
    if (deletedGuestAccounts.length > 0) {
      observableOperations.push(...deletedGuestAccounts.map(account => this.profileService.deleteGuestAccount(event, account)));
    }

    // Update guest accounts whose display name changed
    if (updatedNameGuestAccounts.length > 0) {
      observableOperations.push(...updatedNameGuestAccounts.map(account => this.profileService.updateGuestAccountName(this.originalEvent, event, account.old, account.new)));
    }

    // Update guest accounts whose email changed
    if (updatedEmailGuestAccounts.length > 0) {
      observableOperations.push(...updatedEmailGuestAccounts.map(account => this.profileService.updateGuestAccountEmail(event, account.old, account.new)));
    }

    // Create new guest accounts, if any were added
    if (newGuestAccounts.length > 0) {
      observableOperations.push(...newGuestAccounts.map(account => this.profileService.createGuestAccount(event, account).pipe(
        switchMap(createdProfile => {
          // Go through each of our matches and update the userID representing this account
          matches.forEach(match => {
            Object.keys(match.roleUserMapping).forEach(role => {
              if (match.roleUserMapping[role] == account.sub) {
                match.roleUserMapping[role] = createdProfile.sub;
              }
            });
          });
          return of(createdProfile);
        })
      )));
    }

    return observableOperations;
  }

  // --------------------------------------------------------------------------
  private createMatchEditOperations(event: FacilitationEvent, matches: Match[]): Observable<any>[] {
    let newMatches: Match[] = [];
    let updatedMatches: Match[] = [];
    matches.forEach(match => {
      // If the match doesn't have an event id, then it's new, so it needs to be added
      if (!match.event) {
        match.event = event.id;
        newMatches.push(match);
      }
      else {
        // Otherwise, check if the display name or email changed since we have to handle those differently
        let existingMatch = this.originalMatches.find(originalMatch => originalMatch.id == match.id);
        if (!!existingMatch) {
          // If the event name or enabled state changed, then mark it for updating
          if (match.name != existingMatch.name || match.enabled != existingMatch.enabled) {
            updatedMatches.push(match);
          }
        }
      }
    })
    let deletedMatches = this.originalMatches.filter(originalMatch => matches.every(match => match.id != originalMatch.id));

    let observableOperations: Observable<any>[] = [];

    // Delete matches, if any were removed
    if (deletedMatches.length > 0) {
      observableOperations.push(...deletedMatches.map(match => this.matchService.delete(match)));
    }

    // Update matches whose attributes changed
    if (updatedMatches.length > 0) {
      observableOperations.push(...updatedMatches.map(match => this.matchService.update(match)));
    }

    // Create matches, if any were added
    if (newMatches.length > 0) {
      observableOperations.push(...newMatches.map(match => this.matchService.create(match)));
    }

    return observableOperations;
  }
}
