import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Observable, of, switchMap } from 'rxjs';
import { FacilitationEventService } from 'src/app/services/facilitation-event.service';
import { LoadingService } from 'src/app/services/loading.service';
import { MatchService } from 'src/app/services/match.service';
import { ProfileService } from 'src/app/services/profile.service';
import { FacilitationEvent } from 'src/app/util/facilitation-event';
import { Game } from 'src/app/util/game';
import { Match } from 'src/app/util/match';
import { Profile } from 'src/app/util/profile';
import { FacilitationEventEditorOutput } from '../facilitation-event-editor/facilitation-event-editor.component';

// UI for gathering all information needed for creating a new facilitation event
@Component({
  selector: 'facilitation-event-new',
  templateUrl: './facilitation-event-new.component.html',
  styleUrls: ['./facilitation-event-new.component.scss']
})
export class FacilitationEventNewComponent {
  public currentProfile!: Profile;
  public event: FacilitationEvent = new FacilitationEvent();
  public events!: FacilitationEvent[];
  public games!: Game[];
  public guestAccounts: Profile[] = [];
  public matches: Match[] = [];
  public profiles!: Profile[];

  // --------------------------------------------------------------------------
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private facilitationEventService: FacilitationEventService,
    private matchService: MatchService,
    private profileService: ProfileService,
    private loadingService: LoadingService,
  ) {
    this.route.data.subscribe(({ currentProfile, events, games, profiles }) => {
      this.currentProfile = currentProfile;
      this.events = events;
      this.games = games;
      this.profiles = profiles.filter((profile: Profile) => !profile.event);
    });
  }

  // --------------------------------------------------------------------------
  create(parameters: FacilitationEventEditorOutput): void {
    this.loadingService.loading = true;

    let event = parameters.event;
    let guestAccounts = parameters.guestAccounts;
    let matches = parameters.matches;

    // First create the actual event
    this.facilitationEventService.create(event).pipe(
      // Create any guest accounts now that we have an event to connect them to
      switchMap((newEvent: FacilitationEvent) => this.createGuestAccounts(newEvent, guestAccounts, matches)),
      // Create any matches now that we have an event and profiles created to connect them to
      switchMap((newEvent: FacilitationEvent) => this.createMatches(newEvent, matches))
      // we rely on receiving a websocket 'event-create` signal to update the list in the UI.
    ).subscribe({
      next: () => {
        this.router.navigateByUrl('/match');
      },
      error: () => {
        this.loadingService.loading = false;
      }
    });
  }

  // --------------------------------------------------------------------------
  private createGuestAccounts(newEvent: FacilitationEvent, guestAccounts: Profile[], matches: Match[]): Observable<FacilitationEvent> {
    // Create any guest accounts now that we have an event to connect them to
    if (guestAccounts.length > 0) {
      return forkJoin(guestAccounts.map(account => {
        return this.profileService.createGuestAccount(newEvent, account, false).pipe(
          switchMap(createdProfile => {
            // Go through each of our matches and update the userID representing this account
            matches.forEach(match => {
              Object.keys(match.roleUserMapping).forEach(role => {
                if (match.roleUserMapping[role] == account.sub) {
                  match.roleUserMapping[role] = createdProfile.sub;
                }
              });
            });
            return of(createdProfile);
          })
        );
      })).pipe(switchMap(() => {
        this.profileService.broadcastProfileUpdate();
        return of(newEvent);
      }));
    }
    return of(newEvent);
  }

  // --------------------------------------------------------------------------
  private createMatches(newEvent: FacilitationEvent, matches: Match[]): Observable<any> {
    // Update the event ID on all the matches we want to create
    matches.forEach(match => match.event = newEvent.id);

    if (matches.length > 0) {
      return forkJoin(matches.map(match => this.matchService.create(match)));
    }
    return of(null);
  }
}
