<div class="p-component detail-root">
  <div class="standard-container detail-container">
    <div class="content-header">Edit Profile</div>
    <div class="content">
      <form action="">
        <div futuretech field class="spacer top column align-center half-width fit-spacious big"></div>

        <div futuretech field class="label column align-center half-width fit-spacious big">
          <!--suppress XmlDuplicatedId -->
          <label id="displayname">{{profile.displayName}}</label>
        </div>

        <div futuretech field class="label column align-center half-width fit-spacious medium">
          <label id="email">{{profile.email}}</label>
        </div>

        <div futuretech field class="spacer bottom column align-center half-width fit-spacious big"></div>

        <!-- <div class="avatar">
          <div class="avatar-pic">
            <i class="pi pi-user"
              [class]="{'display-none': !profile.iconURL || (profile.iconURL && profile.iconURL.length == 0) }">
            </i>
            <img
              [class]="{'display-none': profile.iconURL && profile.iconURL.length > 0 }"
              src="https://primefaces.org/cdn/primeng/images/galleria/galleria1.jpg" alt="" />
          </div>

          <div class="buttons">
            <p-button pRipple type="button" icon="pi pi-pencil" styleClass="p-button-rounded" class="white-icon" ></p-button>
          </div>
        </div> -->

        <div futuretech field class="spacer top column align-center half-width fit-spacious big"></div>

        <div futuretech field class="inputText column align-center half-width fit-spacious">
          <!--suppress XmlDuplicatedId -->
          <label id="displayname">Edit Display Name</label>
          <input pInputText type="text" [(ngModel)]="profile.displayName" name="displayname"/>
        </div>

        <!-- <div futuretech field class="inputText column align-center half-width fit-spacious">
          <label id="email">Email</label>
          <input pInputText type="email" [(ngModel)]="profile.email" name="email" [disabled]="true" />
        </div> -->
        <div futuretech field class="spacer top bottom column align-center half-width fit-spacious big"></div>

        <div futuretech field class="button row align-center full-width padding-normal display-block"
        *ngIf="!isEccoDeployment()">
          <p-button class="margin-1 block" (click)="showEmailDialog(true)">Change Email</p-button>
          <p-button class="margin-1 block" (click)="showPasswordDialog(true)">Change Password</p-button>
        </div>
      </form>
    </div>

    <div class="content-footer">
      <p-button class="margin-2" (onClick)="save()">Save</p-button>
      <p-button class="margin-2" (onClick)="cancel()">Cancel</p-button>
    </div>
  </div>
</div>

<!--<editor-fold desc="Change Password Dialog">------------------------------------->
<p-dialog header="Change Password" [(visible)]="displayPasswordDialog" [style]="{width: '50vw'}" [modal]="true">
  <div futuretech field class="inputText column align-center full-width fit-spacious">
    <label id="oldPassword">Old Password</label>
    <p-password futuretech class="align-center fit-spacious full-width"
      name="oldPassword"
      [(ngModel)]="oldPassword"
      [showClear]="true"
      [strongRegex]="strongRegex"
      [toggleMask]="true"
      [feedback]="false"
      >
    </p-password>
  </div>

  <div futuretech field class="inputText column align-center full-width fit-spacious">
    <label id="newPassword">New Password</label>
    <p-password futuretech class="align-center fit-spacious full-width"
      name="newPassword"
      [(ngModel)]="newPassword"
      [showClear]="true"
      [strongRegex]="strongRegex"
      [toggleMask]="true"
      weakLabel="Weak: You must enter a strong password"
      mediumLabel="Medium: You must enter a strong password"
      strongLabel="Strong: Valid password">
    </p-password>
  </div>

  <div futuretech field class="inputText column align-center full-width fit-spacious">
    <label id="confirmPassword">Confirm Password</label>
    <p-password futuretech class="align-center fit-spacious full-width"
      name="confirmPassword"
      [(ngModel)]="confirmPassword"
      [showClear]="true"
      [strongRegex]="strongRegex"
      [toggleMask]="true"
      weakLabel="Weak: You must enter a strong password"
      mediumLabel="Medium: You must enter a strong password"
      strongLabel="Strong: Valid password">
    </p-password>
  </div>

  <div futuretech field class="spacer column align-center full-width fit-spacious" >
    <label></label>
  </div>

  <div futuretech field class="spacer column align-center full-width fit-spacious" >
    <label></label>
  </div>

  <div futuretech field class="message column align-center full-width fit-spacious">
    <label id="message"
      class="message"
      [class]="{'password-match': newPassword.length > 0 && (newPassword == confirmPassword), 'password-entered': newPassword.length > 0 && (newPassword != confirmPassword)}">
      <span class="password-entered">Passwords do not match</span>
      <span class="password-match">Passwords match!</span>
    </label>
  </div>

  <div *ngIf="authError" futuretech field class="message column align-center full-width fit-spacious">
    <span>{{authError}}</span>
  </div>

  <div futuretech field class="inputText column align-center full-width fit-spacious"
  *ngIf="!isEccoDeployment()">
    <p-button class="margin-2" (click)="changePassword()">Change Password</p-button>
    <p-button class="margin-2" (click)="showPasswordDialog(false)">Cancel</p-button>
  </div>
</p-dialog>
<!--</editor-fold>-->

<!--<editor-fold desc="Change Email Dialog">---------------------------------------->
<p-dialog header="Change Email Address" [(visible)]="displayEmailDialog" [style]="{width: '50vw'}" [modal]="true">
  <form action="">

    <p>To change your email address, you must enter the confirmation code sent to your current email address.</p>

    <div futuretech field class="inputText column align-center full-width fit-spacious">
      <label id="newEmail">New Email Address</label>
      <input pInputText type="email" [(ngModel)]="profile.email" name="newEmail"/>
    </div>

    <div futuretech field class="button row align-center full-width padding-normal block" [class]="{'hide': verificationCodeSent}">
      <p-button type="submit" class="margin-2 inline-block" (click)="submitEmailChange()">Send Verification Code</p-button>
    </div>
  </form>
  <form action="" [class]="{'hide': !verificationCodeSent}">

    <div futuretech field class="inputText column align-center full-width fit-spacious" >
      <label id="confirmationCode">Verification Code</label>
      <input pInputText type="text" [(ngModel)]="confirmationCode" name="confirmationCode"/>
    </div>

    <div futuretech field class="spacer column align-center full-width fit-spacious" >
      <label ></label>
    </div>

    <div futuretech field class="inputText column align-center full-width fit-spacious"  >
      <p-button class="margin-2" (click)="submitEmailChange()">Resend Verification Code</p-button>
      <p-button class="margin-2" (click)="submitEmailVerificationCode()">Submit Verification</p-button>
    </div>
  </form>
</p-dialog>
<!--</editor-fold>-->
