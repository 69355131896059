<div futuretech class="p-component main-content">
  <div class="main-content-container">

    <ng-container *ngFor="let game of games">
      <div class="game-list-item standard-container hoverable" [routerLink]="'./' + game.id">
        <div class="game-list-item-inner">
          <div class="game-thumbnail">
            <img src={{game.image}} alt="">
          </div>
          <div class="game-text">
            <h2 class="game-name">{{game.name}}</h2>
          </div>
          <div class="buttons">
            <ng-container *ngIf="!currentProfile.isGuest()">
              <p-button pRipple type="button" class="block" label="New Match" icon="pi pi-plus" (onClick)="new(game); $event.stopPropagation()"></p-button>
            </ng-container>
            <p-button *ngIf="matchCount[game.id] == 1" pRipple type="button" class="block" icon="pi pi-eye" label="View {{matchCount[game.id]}} Match" (onClick)="view(game); $event.stopPropagation()" [disabled]="matchCount[game.id] == 0"></p-button>
            <p-button *ngIf="matchCount[game.id] > 1" pRipple type="button" class="block" icon="pi pi-eye" label="View {{matchCount[game.id]}} Matches" (onClick)="view(game); $event.stopPropagation()" [disabled]="matchCount[game.id] == 0"></p-button>
            <p-button *ngIf="matchCount[game.id] == 0" pRipple type="button" class="block" icon="pi pi-eye-slash" label="No Matches" (onClick)="view(game); $event.stopPropagation()" [disabled]="matchCount[game.id] == 0"></p-button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
