import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { AwsConfigService } from './aws-config.service';
import { MessageService } from 'primeng/api';

// Service to route HTTP calls and responses from other services and the server
// This service shouldn't have many brains/logic on the calls themselves (like
// parsing the response), since that should be done in the other services
@Injectable({
  providedIn: 'root'
})
export class HTTPService {
  // --------------------------------------------------------------------------
  constructor (
    private http: HttpClient,
    private awsConfig: AwsConfigService,
    private messageService: MessageService
  ) { }

  // --------------------------------------------------------------------------
  get(route: string, params?: any): Observable<any> {
    // Default route handling
    return this.http
      .get(this.awsConfig.ApiUrl() + route, { params: new HttpParams({ fromObject: params }) })
      .pipe(catchError(this.handleError.bind(this)));
  }

  // --------------------------------------------------------------------------
  post(route: string, body?: any, options?:any): Observable<any> {
    // Default route handling
    return this.http.post(this.awsConfig.ApiUrl() + route, body, options).pipe(catchError(this.handleError.bind(this)));
  }

  // --------------------------------------------------------------------------
  put(route: string, params?: any): Observable<any> {
    // Default route handling
    return this.http.put(this.awsConfig.ApiUrl() + route, params).pipe(catchError(this.handleError.bind(this)));
  }

  // --------------------------------------------------------------------------
  patch(route: string, body?:any, options?:any): Observable<any>{
    return this.http.patch(this.awsConfig.ApiUrl() + route, body, options).pipe(catchError(this.handleError.bind(this)));
  }

  // --------------------------------------------------------------------------
  delete(route: string, params?: any): Observable<any> {
    // Default route handling
    return this.http.delete(this.awsConfig.ApiUrl() + route, { body: params }).pipe(catchError(this.handleError.bind(this)));
  }

  // --------------------------------------------------------------------------
  private handleError(err: HttpErrorResponse): Observable<any> {
    let error = err.error.message || err.message;
    console.error(JSON.stringify(error));
    this.messageService.add({
      severity: 'error',
      detail: `The server gave us the following error: ${error}`,
      sticky: true
    });
    throw err;
  }
}
