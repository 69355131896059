import { Injectable } from '@angular/core';
import { map, Observable, of, switchMap, tap } from 'rxjs';

import { HTTPService } from './http.service';

import { Game } from '../util/game';

// Service for gathering Games from the server (both a full list and individually)
@Injectable({
  providedIn: 'root'
})
export class GameService {
  public games?: Game[];

  // --------------------------------------------------------------------------
  constructor(
    private httpService: HTTPService,
  ) { }

  // --------------------------------------------------------------------------
  getList(): Observable<Game[]> {
    if (!!this.games) {
      return of(this.games);
    }
    return this.httpService.get('game').pipe(
      // Convert results from the server to actual Game objects
      switchMap(result => {
        return of(result.map((item: any) => {
          let game = new Game();
          game.id = item.id;
          game.name = item.name;
          game.description = item.description;
          game.image = item.image;
          game.thumbnail = item.thumbnail;
          game.screenshots = item.screenshots;
          game.minPlayers = item.minPlayers;
          game.maxPlayers = item.maxPlayers;
          game.roles = item.roles;
          game.active = item.active
          return game;
        })
        // Sort our game list by name
        .sort((a: Game, b: Game) => a.name.localeCompare(b.name)))
      }),
      // Cache our game list to reuse later to speed up the client experience
      tap(games => this.games = games)
    );
  }

  // --------------------------------------------------------------------------
  get(gameId: string): Observable<Game> {
    return this.httpService.get(`game`, { gid: gameId }).pipe(
      map(result => result as Game)
    );
  }
}
