import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';

import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { CarouselModule } from 'primeng/carousel';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { InputNumberModule } from 'primeng/inputnumber';
import { TableModule } from 'primeng/table';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { PasswordModule } from 'primeng/password';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { ImageModule } from 'primeng/image';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { FieldsetModule } from 'primeng/fieldset';
import { CalendarModule } from 'primeng/calendar';
import { InputSwitchModule } from 'primeng/inputswitch';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InplaceModule } from 'primeng/inplace';
import { ToastModule } from 'primeng/toast';
import { OverlayPanelModule } from 'primeng/overlaypanel';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ProfileEditorComponent } from './components/profile-editor/profile-editor.component';
import { FacilitationEventListComponent } from './components/facilitation-event-list/facilitation-event-list.component';
import { FacilitationEventDetailComponent } from './components/facilitation-event-detail/facilitation-event-detail.component';
import { FacilitationEventNewComponent } from './components/facilitation-event-new/facilitation-event-new.component';
import { GameListComponent } from './components/game-list/game-list.component';
import { GameDetailComponent } from './components/game-detail/game-detail.component';
import { MatchListComponent } from './components/match-list/match-list.component';
import { MatchDetailComponent } from './components/match-detail/match-detail.component';
import { MatchViewComponent } from './components/match-view/match-view.component';
import { MatchEditComponent } from './components/match-edit/match-edit.component';
import { MatchNewComponent } from './components/match-new/match-new.component';
import { CurrentProfileComponent } from './components/current-profile/current-profile.component';
import { SiteNavigationComponent } from './components/site-navigation/site-navigation.component';
import { AddPlayerComponent } from './components/add-player/add-player.component';
import { LoadingComponent } from './components/loading/loading.component';
import { JoinedPlayerComponent } from './components/joined-player/joined-player.component';
import { HeaderNavigationComponent } from './components/header-navigation/header-navigation.component';
import { AuthInterceptor } from './interceptors/authentication-interceptor';
import { RepeatDirective } from './directives/repeat.directive';
import { GuestUserListComponent } from './components/guest-user-list/guest-user-list.component';
import { AddGuestUserComponent } from './components/add-guest-user/add-guest-user.component';
import { MiniMatchListComponent } from './components/mini-match-list/mini-match-list.component';
import { MiniMatchNewComponent } from './components/mini-match-new/mini-match-new.component';
import { FacilitationEventEditorComponent } from './components/facilitation-event-editor/facilitation-event-editor.component';
import { MessageService } from 'primeng/api';

@NgModule({
  declarations: [
    AppComponent,
    ProfileEditorComponent,
    FacilitationEventListComponent,
    FacilitationEventDetailComponent,
    FacilitationEventNewComponent,
    GameListComponent,
    GameDetailComponent,
    MatchListComponent,
    MatchDetailComponent,
    MatchViewComponent,
    MatchEditComponent,
    MatchNewComponent,
    CurrentProfileComponent,
    SiteNavigationComponent,
    LoadingComponent,
    AddPlayerComponent,
    JoinedPlayerComponent,
    HeaderNavigationComponent,
    RepeatDirective,
    GuestUserListComponent,
    AddGuestUserComponent,
    MiniMatchListComponent,
    MiniMatchNewComponent,
    FacilitationEventEditorComponent
  ],
  imports: [
    AmplifyAuthenticatorModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ButtonModule,
    InputTextModule,
    TabMenuModule,
    TabViewModule,
    CarouselModule,
    DropdownModule,
    CheckboxModule,
    InputNumberModule,
    TableModule,
    ProgressSpinnerModule,
    PasswordModule,
    AvatarModule,
    AvatarGroupModule,
    ImageModule,
    DialogModule,
    ConfirmDialogModule,
    DynamicDialogModule,
    CalendarModule,
    InputSwitchModule,
    FieldsetModule,
    AutoCompleteModule,
    InplaceModule,
    ToastModule,
    OverlayPanelModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    MessageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
