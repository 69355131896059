import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Profile } from '../../util/profile'
import { DialogService } from 'primeng/dynamicdialog';
import { AddGuestUserComponent } from 'src/app/components/add-guest-user/add-guest-user.component';
import { Match } from 'src/app/util/match';
import { FacilitationEvent } from 'src/app/util/facilitation-event';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'guest-user-list',
  templateUrl: './guest-user-list.component.html',
  styleUrls: ['./guest-user-list.component.scss'],
  providers: [DialogService]
})
export class GuestUserListComponent {
  @Input('current-profile') currentProfile!: Profile;
  @Input() event!: FacilitationEvent;
  @Input() set matches(matches: Match[]) {
    this.guestAccountMatchCounts = {};
    matches.forEach(match => {
      Object.values(match.roleUserMapping).forEach(assignedUserId => {
        if (!!assignedUserId) {
          if (!this.guestAccountMatchCounts.hasOwnProperty(assignedUserId)) {
            this.guestAccountMatchCounts[assignedUserId] = 0;
          }
          ++this.guestAccountMatchCounts[assignedUserId];
        }
      });
    })
  };
  @Input() value!: Profile[];
  @Output() valueChange: EventEmitter<Profile[]> = new EventEmitter();
  public guestAccountMatchCounts!: { [id: string]: number };
  private dialogWidth: string = '30em';
  private dialogHeight: string = '25em';
  private newAccountId: number = 1;

  constructor(
    public dialogService: DialogService,
    private messageService: MessageService
  ) { }

  // --------------------------------------------------------------------------
  getUsername(account: Profile): string {
    return this.event.getGuestUsername(account);
  }

  // --------------------------------------------------------------------------
  openCreateDialog(): void {
    const ref = this.dialogService.open(AddGuestUserComponent, { 
      data: {
        placeholder: this.currentProfile.email,
        account: new Profile(),
        buttonText: 'Create'
      },
      header: 'Create Guest User',
      width: this.dialogWidth,
      height: this.dialogHeight
    });

    ref.onClose.subscribe((account: Profile | undefined = undefined) => {
      if (account != undefined) {
        // Give this new account a temporary sub so it can be used in the event wizard
        account.sub = `${this.newAccountId++}`;
        this.valueChange.emit([...this.value, account]);
      }
    });
  }

  // --------------------------------------------------------------------------
  openEditDialog(account: Profile, accountIndex: number): void {
    const ref = this.dialogService.open(AddGuestUserComponent, { 
      data: {
        placeholder: this.currentProfile.email,
        account: Profile.clone(account),
        buttonText: 'Update'
      },
      header: 'Update Guest User',
      width: this.dialogWidth,
      height: this.dialogHeight
    });

    ref.onClose.subscribe((editedAccount: Profile | undefined = undefined) => {
      if (editedAccount != undefined) {
        this.value[accountIndex] = editedAccount;
        // Notify everyone about the change
        this.valueChange.emit([...this.value]);
      }
    });
  }

  // --------------------------------------------------------------------------
  copyClipboard(account: Profile) : void {
    navigator.clipboard.writeText(this.getUsername(account));
    this.addSingleToast(this.getUsername(account));
  }

  // --------------------------------------------------------------------------
  canDelete(account: Profile): boolean {
    // Guest accounts can't be deleted if they are assigned to any matches
    return !this.guestAccountMatchCounts[account.sub];
  }

  // --------------------------------------------------------------------------
  delete(accountToDelete: Profile): void {
    this.valueChange.emit(this.value.filter(account => account != accountToDelete));
  }
  
  // --------------------------------------------------------------------------
  private addSingleToast(username: string) {
    this.messageService.add({
      severity: 'info', 
      detail: `${username} copied to clipboard!`
    });
  }
}
