import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FacilitationEventService } from 'src/app/services/facilitation-event.service';
import { LoadingService } from 'src/app/services/loading.service';
import { MatchService } from 'src/app/services/match.service';
import { ProfileService } from 'src/app/services/profile.service';
import { FacilitationEvent } from 'src/app/util/facilitation-event';
import { Game, GameRole } from 'src/app/util/game';
import { Match } from 'src/app/util/match';
import { Profile } from 'src/app/util/profile';
import { Util } from 'src/app/util/util';
import {AwsConfigService} from "../../services/aws-config.service";

// UI for displaying the details for one particular match
// The match in question is gathered from the route resolver, so we know
// it exists if we got here
@Component({
  selector: 'match-detail',
  templateUrl: './match-detail.component.html',
  styleUrls: ['./match-detail.component.scss']
})
export class MatchDetailComponent implements OnDestroy {
  public currentProfile!: Profile;
  public match!: Match;
  public eventName!: string;
  public roles: GameRole[] = [];
  public availableRoles!: GameRole[];
  public profiles!: Profile[];
  public canPlayMatch!: boolean;

  // --------------------------------------------------------------------------
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private facilitationEventService: FacilitationEventService,
    private matchService: MatchService,
    private profileService: ProfileService,
    private loadingService: LoadingService,
  ) {
    this.route.data.subscribe(({ currentProfile, events, games, match, profiles }) => {
      this.currentProfile = currentProfile;
      this.roles = games.find((game: Game) => game.id == match.game).roles;
      this.match = match;
      this.profiles = profiles;
      this.updateAvailableRoles();
      this.updatePlayButton();
      this.onEventListUpdated(events);
    });
    this.facilitationEventService.addListListener(this.onEventListUpdated);
    this.matchService.addListListener(this.onMatchListUpdated);
    this.profileService.addListListener(this.onProfilesUpdated);
  }

  // --------------------------------------------------------------------------
  ngOnDestroy(): void {
    this.facilitationEventService.removeListListener(this.onEventListUpdated);
    this.matchService.removeListListener(this.onMatchListUpdated);
    this.profileService.removeListListener(this.onProfilesUpdated);
  }

  // --------------------------------------------------------------------------
  join(role: string): void {
    this.loadingService.loading = true;
    this.matchService.join(this.match, role).subscribe({
      next: () => {
        this.loadingService.loading = false;
      },
      error: () => {
        this.loadingService.loading = false;
      }
    });
  }

  // --------------------------------------------------------------------------
  leave(): void {
    this.loadingService.loading = true;
    // Remove current user from role mappings
    Object.keys(this.match.roleUserMapping).forEach(role => {
      if (this.match.roleUserMapping[role] == this.currentProfile.sub) {
        this.match.roleUserMapping[role] = undefined;
      }
    });
    this.matchService.leave(this.match).subscribe({
      next: () => {
        this.loadingService.loading = false;
      },
      error: () => {
        this.loadingService.loading = false;
      }
    });
  }

  // --------------------------------------------------------------------------
  play(): void {
    this.matchService.play(this.match);
  }

  // --------------------------------------------------------------------------
  private onEventListUpdated = (events: FacilitationEvent[]): void => {
    this.eventName = events.find((event: FacilitationEvent) => event.id == this.match.event)?.name || 'None';
  }

  // --------------------------------------------------------------------------
  private onMatchListUpdated = (matches: Match[]): void => {
    // Make sure our match is up to date
    let newMatchData = matches.find(match => match.id == this.match.id);
    if (!!newMatchData) {
      this.match = newMatchData;
      this.updateAvailableRoles();
      this.updatePlayButton();
    }
    // If our match got deleted, then reroute the user to the match list
    else {
      this.router.navigate(['/match']);
    }
  }

  // --------------------------------------------------------------------------
  private onProfilesUpdated = (profiles: Profile[]): void => {
    this.profiles = profiles;
  }

  // --------------------------------------------------------------------------
  private updateAvailableRoles(): void {
    // Get the full list of available roles and remove any roles that have already been taken
    this.availableRoles = Util.getAvaiableRoles(this.roles, this.match.playerCount).filter((role: GameRole) => !this.match.roleUserMapping.hasOwnProperty(role.gameRole));
  }

  // --------------------------------------------------------------------------
  private updatePlayButton(): void {
    this.canPlayMatch = this.match.enabled && !!this.match.gid && this.match.isPlayer(this.currentProfile.sub);
  }
}
