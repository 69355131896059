import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { ProfileService } from '../services/profile.service';
import { Profile } from '../util/profile';

// Route guard to make sure a user is logged in before they are allowed to go to the route
@Injectable({
  providedIn: 'root'
})
export class GuestGuard implements CanActivate {
  // --------------------------------------------------------------------------
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private profileService: ProfileService
  ) { }

  // --------------------------------------------------------------------------
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.authenticationService.getCurrentUserId().pipe(
      switchMap((currentUserId?: string) => !!currentUserId ? this.profileService.get(currentUserId) : of(undefined)),
      switchMap((currentProfile?: Profile) => {
        if (!!currentProfile && !currentProfile.isGuest()) {
          return of(true);
        }
        this.router.navigateByUrl('');
        return of(false);
      })
    );
  }
}
