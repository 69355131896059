import { Component, Input } from '@angular/core';
import { Profile, ProfileType } from '../../util/profile'
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'add-guest-user',
  templateUrl: './add-guest-user.component.html',
  styleUrls: ['./add-guest-user.component.scss']
})
export class AddGuestUserComponent {
  public editUpdateButtonText: string = "";
  public placeholder!: string;
  @Input() account!: Profile;

  constructor(
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef
  ) {
    this.account = config.data.account;
    this.editUpdateButtonText = config.data.buttonText;
    this.placeholder = config.data?.placeholder;
  }

  // --------------------------------------------------------------------------
  createGuestUser() {
    this.account.type = ProfileType.Guest;
    if (!this.account.email) {
      this.account.email = this.placeholder;
    }
    this.ref.close(this.account);
  }

  // --------------------------------------------------------------------------
  cancel() {
    this.ref.close();
  }
  
  // --------------------------------------------------------------------------
  isValidDisplayName(model?: string) : boolean {
    return model != undefined && model?.length > 0;
  }
  
}
