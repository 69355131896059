<div class="p-component detail-root" >
  <div class="standard-container detail-container">
    <div class="content-header">View Match</div>
    <div class="content footer-L overflow-hidden">
      <div class="left-section">
        <div futuretech field class="subheader row full-width">
          <label>Match Details</label>
        </div>

        <div futuretech field class="display-only row full-width row-lines">
          <label>Match Name</label>
          <span>{{match.name}}</span>
        </div>
        
        <div futuretech field class="display-only row full-width row-lines">
          <label>Event</label>
          <span>{{eventName}}</span>
        </div>
      </div>

      <div class="right-section">
        <div futuretech field class="subheader row full-width">
          <label>Players</label>
        </div>

        <joined-player
        [match]="match"
          [current-profile]="currentProfile"
          [profiles]="profiles"
          [roles]="roles"
          [available-roles]="availableRoles"
          [player-mappings]="match.roleUserMapping" 
          (onJoin)="join($event)"
          (onLeave)="leave()"
          (onJoin)="join($event)">
        </joined-player>
      </div>
    </div>

    <div class="content-footer flex height-L">
      <p-fieldset futuretech class="play" legend="Go to Match">
        <p-button class="margin-1" [disabled]="!canPlayMatch" (onClick)="play()">
          <span [class]="{'display-none': !match.isFinished(currentProfile.sub)}">View</span>
          <span [class]="{'display-none': match.isFinished(currentProfile.sub)}">Play</span>
        </p-button>
      </p-fieldset>
    </div>
  </div>
</div>
