<div futuretech class="profile p-component header" >
  <div class="profile-name-and-pic">
    <div class="displayname" (click)="edit()">Welcome, {{currentProfile.displayName}}!</div>
    <!-- <ng-container *ngIf="!currentProfile.isGuest()">
      <p-avatar class="profile-avatar" icon="pi pi-user" shape="circle" (click)="edit()" ></p-avatar>
    </ng-container> -->
    <button class="logout" (click)="logout()" title="Logout">
      <i class="pi pi-sign-out"></i>
    </button>
  </div>
</div>
