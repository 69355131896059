import { Component, OnDestroy, OnInit } from '@angular/core';
import { Match, RoleUserMapping } from 'src/app/util/match';
import { Game } from 'src/app/util/game';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FacilitationEvent } from 'src/app/util/facilitation-event';
import { Profile } from 'src/app/util/profile';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'mini-match-new',
  templateUrl: './mini-match-new.component.html',
  styleUrls: ['./mini-match-new.component.scss']
})
export class MiniMatchNewComponent implements OnInit, OnDestroy {
  public match!: Match;
  public event!: FacilitationEvent;
  public games!: Game[];
  public profiles!: Profile[];
  public selectedGame?: Game;
  public buttonLabel!: string;
  public disabledReason?: string;

  // --------------------------------------------------------------------------
  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private profileService: ProfileService,
  ) {
    this.match = this.config.data.match;
    this.event = this.config.data.event;
    this.games = this.config.data.games;
    this.profiles = this.config.data.profiles;
    this.buttonLabel = this.config.data.buttonLabel;
    if (!!this.match.game) {
      this.selectedGame = this.games.find(game => game.id == this.match.game);
    }
    this.updateDisabledReason();
  }

  // --------------------------------------------------------------------------
  ngOnInit(): void {
    this.profileService.addListListener(this.onProfilesUpdated);
  }

  // --------------------------------------------------------------------------
  ngOnDestroy(): void {
    this.profileService.removeListListener(this.onProfilesUpdated);
  }

  // --------------------------------------------------------------------------
  cancel() {
    this.ref.close();
  }

  // --------------------------------------------------------------------------
  createMatch() {
    if (!this.disabledReason) {
      this.ref.close(this.match);
    }
  }

  // --------------------------------------------------------------------------
  onSelectedGameChanged() {
    if (!!this.selectedGame) {
      this.match.setGame(this.selectedGame);
    }
  }

  // --------------------------------------------------------------------------
  onRoleMappingChanged(roleUserMapping: RoleUserMapping): void {
    this.match.roleUserMapping = roleUserMapping;
    this.updateDisabledReason();
  }

  // --------------------------------------------------------------------------
  updateDisabledReason(): void {
    if (!this.match.name) {
      this.disabledReason = 'Match needs a name';
    }
    else if (!this.match.isFull()) {
      this.disabledReason = 'All matches must have all player slots filled';
    }
    else if (!!this.selectedGame && this.selectedGame.roles.some(role => !!role.required && !this.match.roleUserMapping.hasOwnProperty(role.gameRole))) {
      this.disabledReason = "All required roles must be assigned";
    }
    else {
      this.disabledReason = undefined;
    }
  }

  // --------------------------------------------------------------------------
  private onProfilesUpdated = (profiles: Profile[]): void => {
    this.profiles = profiles;
  }
}
