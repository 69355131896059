import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CurrentProfileComponent} from './components/current-profile/current-profile.component';
import {
  FacilitationEventDetailComponent
} from './components/facilitation-event-detail/facilitation-event-detail.component';
import {FacilitationEventNewComponent} from './components/facilitation-event-new/facilitation-event-new.component';
import {GameDetailComponent} from './components/game-detail/game-detail.component';
import {GameListComponent} from './components/game-list/game-list.component';
import {MatchDetailComponent} from './components/match-detail/match-detail.component';
import {MatchListComponent} from './components/match-list/match-list.component';
import {MatchNewComponent} from './components/match-new/match-new.component';
import {ProfileEditorComponent} from './components/profile-editor/profile-editor.component';
import {SiteNavigationComponent} from './components/site-navigation/site-navigation.component';
import {AuthenticationGuard} from './routing/authentication.guard';
import {CurrentProfileResolver} from './routing/current-profile.resolver';
import {FacilitationEventListResolver} from './routing/facilitation-event-list.resolver';
import {FacilitationEventResolver} from './routing/facilitation-event.resolver';
import {GameListResolver} from './routing/game-list.resolver';
import {GameResolver} from './routing/game.resolver';
import {HeaderNavigationComponent} from './components/header-navigation/header-navigation.component';
import {MatchListResolver} from './routing/match-list.resolver';
import {MatchResolver} from './routing/match.resolver';
import {ProfileResolver} from './routing/profile.resolver';
import {GuestGuard} from './routing/guest.guard';

const routes: Routes = [
  {
    path: 'profile',
    canActivate: [AuthenticationGuard, GuestGuard],
    children: [
      {path: '', component: HeaderNavigationComponent, outlet: 'header'},
      {
        path: '',
        component: ProfileEditorComponent,
        resolve: {currentProfile: CurrentProfileResolver},
        runGuardsAndResolvers: 'always'
      }
    ],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'game',
    canActivate: [AuthenticationGuard],
    children: [
      {
        path: '', pathMatch: 'full', children: [
          {
            path: '',
            component: HeaderNavigationComponent,
            outlet: 'header',
            data: {label: 'Game List', hideBackButton: true}
          },
          {
            path: '',
            component: CurrentProfileComponent,
            outlet: 'current-profile',
            resolve: {currentProfile: CurrentProfileResolver},
            runGuardsAndResolvers: 'always'
          },
          {
            path: '',
            component: GameListComponent,
            resolve: {currentProfile: CurrentProfileResolver, games: GameListResolver, matches: MatchListResolver},
            runGuardsAndResolvers: 'always'
          },
          {path: '', component: SiteNavigationComponent, outlet: 'footer'},
        ]
      },
      {
        path: ':gid', children: [
          {
            path: '',
            component: HeaderNavigationComponent,
            outlet: 'header',
            data: {label: 'Game List', route: '/game'}
          },
          {
            path: '',
            component: GameDetailComponent,
            resolve: {currentProfile: CurrentProfileResolver, game: GameResolver},
            runGuardsAndResolvers: 'always'
          },
        ]
      }
    ],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'event',
    canActivate: [AuthenticationGuard],
    children: [
      {path: '', redirectTo: '/match', pathMatch: 'full'},
      {
        path: 'new', canActivate: [GuestGuard], children: [
          {
            path: '',
            component: HeaderNavigationComponent,
            outlet: 'header',
            data: {label: 'Matches & Events'}
          },
          {
            path: '',
            component: FacilitationEventNewComponent,
            resolve: {
              currentProfile: CurrentProfileResolver,
              events: FacilitationEventListResolver,
              games: GameListResolver,
              profiles: ProfileResolver
            }
          }
        ]
      },
      {
        path: ':id', canActivate: [GuestGuard], children: [
          {
            path: '',
            component: HeaderNavigationComponent,
            outlet: 'header',
            data: {label: 'Matches & Events', route: '/match'}
          },
          {
            path: '', component: FacilitationEventDetailComponent,
            resolve: {
              currentProfile: CurrentProfileResolver,
              event: FacilitationEventResolver,
              events: FacilitationEventListResolver,
              games: GameListResolver,
              matches: MatchListResolver,
              profiles: ProfileResolver
            }, runGuardsAndResolvers: 'always'
          }
        ]
      }
    ],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'match',
    canActivate: [AuthenticationGuard],
    children: [
      {
        path: '', pathMatch: 'full', children: [
          {
            path: '',
            component: HeaderNavigationComponent,
            outlet: 'header',
            data: {label: 'Matches & Events', hideBackButton: true}
          },
          {
            path: '',
            component: CurrentProfileComponent,
            outlet: 'current-profile',
            resolve: {currentProfile: CurrentProfileResolver},
            runGuardsAndResolvers: 'always'
          },
          {
            path: '',
            component: MatchListComponent,
            resolve: {
              currentProfile: CurrentProfileResolver,
              events: FacilitationEventListResolver,
              games: GameListResolver,
              matches: MatchListResolver
            },
            runGuardsAndResolvers: 'always'
          },
          {path: '', component: SiteNavigationComponent, outlet: 'footer'},
        ]
      },
      {
        path: 'new/:gid', canActivate: [GuestGuard], children: [
          {path: '', component: HeaderNavigationComponent, outlet: 'header', data: {label: 'Game Details'}},
          {
            path: '',
            component: MatchNewComponent,
            resolve: {events: FacilitationEventListResolver, game: GameResolver, profiles: ProfileResolver},
            runGuardsAndResolvers: 'always'
          }
        ]
      },
      {
        path: ':id', children: [
          {
            path: '',
            component: HeaderNavigationComponent,
            outlet: 'header',
            data: {label: 'Matches & Events', route: '/match'}
          },
          {
            path: '',
            component: MatchDetailComponent,
            resolve: {
              currentProfile: CurrentProfileResolver,
              events: FacilitationEventListResolver,
              games: GameListResolver,
              match: MatchResolver,
              profiles: ProfileResolver
            },
            runGuardsAndResolvers: 'always'
          }
        ]
      }
    ],
    runGuardsAndResolvers: 'always'
  },
  {path: '', redirectTo: '/game', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
