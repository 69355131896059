import {Component} from '@angular/core';
import {Auth} from 'aws-amplify';
import {AwsConfigService} from './services/aws-config.service';
import {LoadingService} from './services/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  private signInPlaceholder(): string {
    return this.awsConfigService.isECCODeployment() ? 'Enter Your Email Here' : 'Enter Your Email or Guest Username Here'
  }

  private labelPlaceholder(): string {
    return this.awsConfigService.isECCODeployment() ? 'Email:' : 'Email/Guest Username:'
  }

  public formFields = {
    signIn: {
      username: {
        placeholder: this.signInPlaceholder(),
        isRequired: true,
        label: this.labelPlaceholder()
      },
    },
    signUp: {
      username: {
        placeholder: 'Enter Your Email Here',
        label: 'Email:',
        type: 'email'
      },
    },
  };


  constructor(
    private awsConfigService: AwsConfigService,
    public loadingService: LoadingService
  ) {
    //at the earliest convenient time, configure the AWS system.
    this.awsConfigService.Configure();
  }

  // --------------------------------------------------------------------------
  //Replace the built-in sign up function with this one, which automatically generates
  // a preferred_username from the supplied email address.
  services =  {
    async handleSignUp(formData: Record<string, any>) {
      let {username, password, attributes} = formData;

      //pick off the first bit of the email address to use as a default preferred_username
      var nameParts = username.split("@");
      var emailName = nameParts.length == 2 ? nameParts[0] : username;

      var params = {
        username: username,
        password: password,
        attributes: {
          nickname: emailName,
          email: username
        },
        autoSignIn: {
          enabled: true
        }
      }
      return Auth.signUp(params);
    }
  }
}
