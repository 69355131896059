import { Injectable } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { AwsConfigService } from './aws-config.service';

// Service to route WebSocket calls and responses from other services and the server
// This service shouldn't have many brains/logic on the calls themselves (like
// parsing the response), since that should be done in the other services
// Services can register to listen for specific WebSocket signals
@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  private websocket?: WebSocketSubject<any>;
  private listeners: { [signal: string]: Function[] } = {};
  private enabled!: boolean;

  // --------------------------------------------------------------------------
  constructor(
    private awsConfig: AwsConfigService
  ) {
    // Connect websocket
    let websocketUrl = this.awsConfig.WebsocketUrl();
    if (!!websocketUrl) {
      this.connect(websocketUrl);
    }
    else {
      console.error('Websocket URL is not defined!');
    }
  }

  // --------------------------------------------------------------------------
  on(signal: string, cb: Function): void {
    // If no one has registered for this signal, then create a list of listeners
    if (!this.listeners.hasOwnProperty(signal)) {
      this.listeners[signal] = [];
    }

    // Add our listener
    this.listeners[signal].push(cb);
  }

  // --------------------------------------------------------------------------
  // Tell the server to broadcast the signal to all clients (used to tell all clients to update something)
  broadcast(signal: string, data?: any): void {
    if (!!this.websocket) {
      // Data sent to the server to broadcast to all clients,
      // the data field must be a string.
      const payload = JSON.stringify({ signal: signal, data: data })
      this.websocket.next({ action: 'broadcast', data: payload});
    }
  }

  // --------------------------------------------------------------------------
  setEnabled(enabled: boolean): void {
    this.enabled = enabled;
  }

  // --------------------------------------------------------------------------
  private connect(websocketUrl: string): void {
    this.websocket = webSocket(websocketUrl);
    this.websocket.subscribe({
      // Called whenever the websocket receives a signal from the server
      next: msg => this.onMessageReceived(msg),
      // Called if at any point WebSocket API signals some kind of error.
      error: err => console.log(`socket error: ${JSON.stringify(err, null, 2)}`),
      // Called when connection is closed (for whatever reason).  Try to reconnect
      // todo: doesn't get called when this client decides to disconnect (timeout). Implement a keepalive in the back end or figure out how not to time out.
      complete: () => {
        console.log(`Websocket disconnected, trying to reconnect`);
        this.websocket?.unsubscribe();
        this.connect(websocketUrl);
      },
    });
  }

  // --------------------------------------------------------------------------
  private onMessageReceived(message: any): void {
    if (this.enabled) {
      let signal = message.signal;
      let params = message.data;
      console.log(`Websocket signal ${signal} received, params: ${JSON.stringify(params)}`);
      // If we have a registered listener for this signal, then let them know the signal was received
      if (this.listeners.hasOwnProperty(signal)) {
        this.listeners[signal].forEach(cb => cb(params));
      }
    }
  }
}
