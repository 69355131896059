import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

// A service anyone can use to force a loading screen to show
@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  public loading: boolean = false;

  // --------------------------------------------------------------------------
  constructor(
    private router: Router
  ) {
    // Clear loading screen on location change
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.loading = false;
      }
    });
  }
}
