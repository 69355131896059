<div id="guest-user-list">
  <div class="section-header margin-2-vertical">Guest Accounts</div>
  <p-table futuretech guest-user-list #dt
    [value]="value"
    dataKey="id"
    sortField="name"
    rowGroupMode="subheader"
    groupRowsBy="name"
    [scrollable]="true" >
    <ng-template pTemplate="caption">
      <div class="text-center">
        <p-button pRipple type="button" class="block" label="Guest" icon="pi pi-plus" (onClick)="openCreateDialog()"></p-button>
      </div>
    </ng-template>
    <ng-template pTemplate="body" let-account let-rowIndex="rowIndex">
      <tr>
        <td>
          <div class="guest-accounts">
            <div class="guest-account text-center">
              <div class="guest-account-email">
                <span class="display-name">{{account.displayName}}</span>
                <span class="email">({{account.email}})</span>
                <span class="match-count" title="Number of matches this guest is assigned to">{{guestAccountMatchCounts[account.sub] || 0}}</span>
              </div>
              <div class="username no-user-select cursor-pointer" (click)="copyClipboard(account)" ><span class="label">Username</span> <span class="margin-2-horizontal">{{getUsername(account)}}</span></div>
            </div>
            <div class="buttons">
<!--              <button pButton pRipple type="button" icon="pi pi-pencil" class="p-button-rounded p-button-text clear" (click)="openEditDialog(account, rowIndex)" title="Edit Guest Account"></button>-->
              <button #copyUsername pButton pRipple type="button" icon="pi pi-copy" class="p-button-rounded p-button-text clear" (click)="copyClipboard(account)" title="Copy Guest Account Username" ></button>
<!--              <button pButton pRipple type="button" icon="pi pi-trash" class="p-button-rounded p-button-text clear" (click)="delete(account)" title="Delete Guest Account" [disabled]="!canDelete(account)"></button>-->
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
