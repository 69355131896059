import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { MiniMatchNewComponent } from 'src/app/components/mini-match-new/mini-match-new.component';
import { Game } from 'src/app/util/game';
import { Match, RoleUserMapping } from 'src/app/util/match';
import { FacilitationEvent } from 'src/app/util/facilitation-event';
import { Profile } from 'src/app/util/profile';

// UI for gathering all information needed for creating a new match
@Component({
  selector: 'mini-match-list',
  templateUrl: './mini-match-list.component.html',
  styleUrls: ['./mini-match-list.component.scss'],
  providers: [DialogService]
})
export class MiniMatchListComponent implements OnInit {
  @Input() value!: Match[];
  @Input() games!: Game[];
  @Input() event!: FacilitationEvent;
  @Input() profiles!: Profile[];
  @Output() valueChange: EventEmitter<Match[]> = new EventEmitter();
  public gamesById!: { [id: string]: Game };
  public activeMatchNameEdits: { [id: string]: boolean } = {};
  private dialogWidth: string = '50%';
  private dialogHeight: string = '70%';
  private newMatchId: number = 0;

  // --------------------------------------------------------------------------
  constructor(
    private dialogService: DialogService
  ) { }

  // --------------------------------------------------------------------------
  ngOnInit(): void {
    this.gamesById = {};
    this.games.forEach(game => {
      this.gamesById[game.id] = game;
    });
    this.value.forEach(match => {
      if (!!match.id) {
        this.activeMatchNameEdits[match.id] = false;
      }
    });
  }

  // --------------------------------------------------------------------------
  openCreateDialog(): void {
    const ref = this.dialogService.open(MiniMatchNewComponent, { 
      data: {
        match: new Match(),
        event: this.event,
        games: this.games,
        profiles: this.profiles,
        buttonLabel: 'Create Match'
      },
      header: 'Create New Match',
      width: this.dialogWidth,
      height: this.dialogHeight
    });
    
    ref.onClose.subscribe((match: Match | undefined = undefined) => {
      if (match != undefined) {
        match.id = `${this.newMatchId++}`;
        this.valueChange.emit([...this.value, match]);
      }
    });
  }

  // --------------------------------------------------------------------------
  edit(match: Match, matchIndex: number): void {
    const ref = this.dialogService.open(MiniMatchNewComponent, { 
      data: {
        match: Match.clone(match),
        event: this.event,
        games: this.games,
        profiles: this.profiles,
        buttonLabel: 'Edit Match'
      },
      header: 'Edit Match',
      width: this.dialogWidth,
      height: this.dialogHeight
    });
    
    ref.onClose.subscribe((editedMatch: Match | undefined = undefined) => {
      if (editedMatch != undefined) {
        this.value[matchIndex] = editedMatch;
        this.valueChange.emit([...this.value]);
      }
    });
  }

  // --------------------------------------------------------------------------
  duplicate(match: Match): void {
    let duplicate = Match.duplicate(match);
    delete duplicate.event;
    this.valueChange.emit([...this.value, duplicate]);
  }

  // --------------------------------------------------------------------------
  delete(matchToDelete: Match): void {
    this.valueChange.emit(this.value.filter(match => match != matchToDelete));
  }

  // --------------------------------------------------------------------------
  onSelectedGameChanged(match: Match, gameId: string): void {
    let game = this.games.find(game => game.id == gameId);
    if (!!game) {
      match.setGame(game);
      match.roleUserMapping = {};
    }
    this.valueChange.emit([...this.value]);
  }

  // --------------------------------------------------------------------------
  onMatchAttributeChanged(): void {
    // Just notify everyone that there was a change
    this.valueChange.emit([...this.value]);
  }

  // --------------------------------------------------------------------------
  onRoleMappingChanged(match: Match, roleUserMapping: RoleUserMapping): void {
    match.roleUserMapping = roleUserMapping;
    this.valueChange.emit([...this.value]);
  }
}
