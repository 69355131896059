import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfirmationService, FilterService, MessageService, SortEvent} from 'primeng/api';
import {Table} from 'primeng/table';
import {FacilitationEventService} from 'src/app/services/facilitation-event.service';
import {LoadingService} from 'src/app/services/loading.service';
import {MatchService} from 'src/app/services/match.service';
import {FacilitationEvent} from 'src/app/util/facilitation-event';
import {Game} from 'src/app/util/game';
import {Match} from 'src/app/util/match';
import {Profile} from 'src/app/util/profile';
import {AwsConfigService} from "../../services/aws-config.service";

class MatchListMatch extends Match {
  public status?: string;
  public playerCountSortCount?: number;
}

// UI for displaying a list of matches
// The list is gathered by the route resolver, so we know
// if we get here, we have a valid list of items (even if it's empty)
@Component({
  selector: 'match-list',
  templateUrl: './match-list.component.html',
  styleUrls: ['./match-list.component.scss'],
  providers: [ConfirmationService]
})
export class MatchListComponent implements OnInit, OnDestroy {
  public currentProfile!: Profile;
  public matches: MatchListMatch[] = [];
  public searchFilter: string = "";
  public columnFilter: string = "";
  public currentCol: string = "";
  public eventsById: { [id: string]: FacilitationEvent } = {};
  public gamesById: { [id: string]: Game } = {};
  public matchCount: { [id: string]: number } = {};
  public noEvents!: boolean;
  public noPublicMatches!: boolean;

  @ViewChild('eventMatches', {static: true}) private eventMatches!: Table;
  @ViewChild('publicMatches', {static: true}) private publicMatches?: Table;

  // --------------------------------------------------------------------------
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private filterService: FilterService,
    private facilitationEventService: FacilitationEventService,
    private matchService: MatchService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private loadingService: LoadingService,
    protected configService: AwsConfigService
  ) {
    this.filterService.register('custom-equals', this.customEqualsFilter.bind(this));
    this.filterService.register('custom-contains', this.customContainsFilter.bind(this));
    this.route.data.subscribe(({currentProfile, events, games, matches}) => {
      this.currentProfile = currentProfile;
      games.forEach((game: Game) => this.gamesById[game.id] = game);
      this.onEventListUpdated(events);
      this.onMatchListUpdated(matches);
    });
  }

  // --------------------------------------------------------------------------
  ngOnInit(): void {
    // Get any filters stored from other routes
    var filterCriteria = this.matchService.getFilterCriteria();
    if (filterCriteria.filterValue) {
      // Set our initial filter on the table and its search inputs
      this.applyFilter(filterCriteria.columnId, filterCriteria.filterValue, 'custom-equals');
    }

    // Clear out our route-based filters
    this.matchService.resetFilterCriteria();

    this.facilitationEventService.addListListener(this.onEventListUpdated);
    this.matchService.addListListener(this.onMatchListUpdated);
  }

  // --------------------------------------------------------------------------
  ngOnDestroy(): void {
    this.facilitationEventService.removeListListener(this.onEventListUpdated);
    this.matchService.removeListListener(this.onMatchListUpdated);
  }

  // --------------------------------------------------------------------------
  sortByCreationDates(event: SortEvent): void {
    event.data?.sort((matchA: any, matchB: any): number => {
      if (!!matchA.event && this.eventsById.hasOwnProperty(matchA.event) && !!matchB.event && this.eventsById.hasOwnProperty(matchB.event)) {
        if (matchA.event != matchB.event) {
          let eventA = this.eventsById[matchA.event];
          let eventB = this.eventsById[matchB.event];
          if (eventA.creationDate < eventB.creationDate) {
            return -1;
          }
          if (eventA.creationDate > eventB.creationDate) {
            return 1;
          }
        }
        // If the user hasn't tried to sort using the column headers, sort by match creation date
        if (!event.multiSortMeta) {
          if (matchA.creationDate < matchB.creationDate) {
            return -1;
          }
          if (matchA.creationDate > matchB.creationDate) {
            return 1;
          }
        }
        // Otherwise, sort using the column filters
        else {
          // The property to sort by is in the second field of the multiSortMeta
          let sortProperty = event.multiSortMeta[1];
          if (matchA[sortProperty.field] < matchB[sortProperty.field]) {
            return sortProperty.order;
          }
          if (matchA[sortProperty.field] > matchB[sortProperty.field]) {
            return -sortProperty.order;
          }
        }
      } else if (!!matchA.event) {
        return -1;
      } else if (!!matchB.event) {
        return 1;
      }
      return 0;
    });
  }

  // --------------------------------------------------------------------------
  newEvent(): void {
    this.router.navigate(['event/new']);
  }

  // --------------------------------------------------------------------------
  hasJoined(match: Match): boolean {
    return match.isPlayer(this.currentProfile.sub);
  }

  // --------------------------------------------------------------------------
  goToGameLibrary(): void {
    this.router.navigate(['game']);
  }

  // --------------------------------------------------------------------------
  canPlay(match: Match): boolean {
    return match.enabled && !!match.gid && match.isPlayer(this.currentProfile.sub);
  }

  // --------------------------------------------------------------------------
  play(match: Match): void {
    this.matchService.play(match);
  }

  // --------------------------------------------------------------------------
  canView(match: Match): boolean {
    return match.enabled && !match.gid;
  }

  // --------------------------------------------------------------------------
  select(match: Match): void {
    this.edit(match);
  }

  // --------------------------------------------------------------------------
  editEvent(event: FacilitationEvent): void {
    this.router.navigate(['event/' + event.id]);
  }

  // --------------------------------------------------------------------------
  edit(match: Match): void {
    this.router.navigate([match.id], {relativeTo: this.route});
  }

  // --------------------------------------------------------------------------
  confirmDeleteEvent(event: FacilitationEvent): void {
    this.confirmationService.confirm({
      header: 'Delete Event?',
      message: `Are you sure that you want to delete event <br/><b>${event.name}</b>?`,
      accept: () => {
        //Actual logic to perform a confirmation
        this.deleteEvent(event)
      }
    });
  }

  // --------------------------------------------------------------------------
  confirmDeleteMatch(match: Match): void {
    this.confirmationService.confirm({
      header: 'Delete Match?',
      message: `Are you sure that you want to delete match <br/><b>${match.name}</b> for game <em>${this.gamesById[match.game].name}</em>?`,
      accept: () => {
        //Actual logic to perform a confirmation
        this.deleteMatch(match)
      }
    });
  }

  // --------------------------------------------------------------------------
  whatIsAnEvent(event: FacilitationEvent): void {
    this.confirmationService.confirm({
      header: 'Delete Event?',
      message: `Are you sure that you want to delete event <br/><b>${event.name}</b>?`,
      accept: () => {
        //Actual logic to perform a confirmation
        this.deleteEvent(event)
      }
    });
  }

  // --------------------------------------------------------------------------
  applyFilterGlobal(filter: string, matchMode: string) {
    // filter the whole table from the search box
    this.eventMatches.filterGlobal(filter, matchMode);
    this.publicMatches?.filterGlobal(filter, matchMode);
  }

  // --------------------------------------------------------------------------
  applyFilter(column: string, value: string, mode: string = 'custom-contains') {
    this.columnFilter = value;
    this.currentCol = column;
    this.eventMatches?.filter(value, column, mode);
    this.publicMatches?.filter(value, column, mode);
  }

  // --------------------------------------------------------------------------
  clear() {
    // clear all filter text boxes and models
    if (!this.configService.isECCODeployment()) {
      this.eventMatches.clear();
    }
    this.publicMatches?.clear();

    this.searchFilter = '';
    this.sortByCreationDates({data: this.matches});

    if (this.columnFilter != '') {
      if (!this.configService.isECCODeployment()) {
        this.eventMatches.filterDelay = 0;
      }
      this.applyFilter(this.currentCol, this.columnFilter, 'custom-equals');
      if (!this.configService.isECCODeployment()) {
        this.eventMatches.filterDelay = 300;
      }
      if (!!this.publicMatches) {
        this.publicMatches.filterDelay = 0;
        this.publicMatches.filterDelay = 300;
      }
    }
  }

  // --------------------------------------------------------------------------
  clearAll() {
    // clear all filter text boxes and models
    if (!this.configService.isECCODeployment()) {
      this.eventMatches?.clear();
    }
    this.publicMatches?.clear();

    this.searchFilter = '';
    this.columnFilter = '';
  }

  // --------------------------------------------------------------------------
  clearEventSort(): void {
    if (!this.configService.isECCODeployment()) {
      this.eventMatches._sortOrder = 1;
      this.eventMatches.sortField = 'event';
      this.eventMatches.sortSingle();
    }
  }

  // --------------------------------------------------------------------------
  clearPublicSort(): void {
    if (!!this.publicMatches) {
      this.publicMatches._sortOrder = 1;
      this.publicMatches.sortField = 'creationDate';
      this.publicMatches.sortSingle();
    }
  }

  // --------------------------------------------------------------------------
  private deleteEvent(event: FacilitationEvent): void {
    this.loadingService.loading = true;
    this.facilitationEventService.delete(event).subscribe({
      next: () => {
        this.loadingService.loading = false;
      },
      error: () => {
        this.loadingService.loading = false;
      }
    });
  }

  // --------------------------------------------------------------------------
  private deleteMatch(match: Match): void {
    this.loadingService.loading = true;
    this.matchService.delete(match).subscribe({
      next: () => {
        this.loadingService.loading = false;
      },
      error: () => {
        this.loadingService.loading = false;
      }
    });

  }

  // --------------------------------------------------------------------------
  private onEventListUpdated = (events: FacilitationEvent[]): void => {
    // Fill out our map of events by name
    this.eventsById = {};
    events.forEach(event => {
      if (!!event.id) {
        this.eventsById[event.id] = event;
      }
    });

    // Update the match list with all real matches (take out the ones we added for empty events)
    this.onMatchListUpdated(this.matches.filter(match => !!match.name));
    this.checkPublicMatches();
    if (!this.configService.isECCODeployment()) {
      this.checkEvents();
    }

    // If the current user is a guest account, auto-expand the one event they should see
    // We have to wait a frame just to make sure the table actually exists first
    if (this.currentProfile.isGuest()) {
      if (events.length > 0) {
        this.matches.forEach(match => {
          if (match.event == events[0].id) {
            setTimeout(() => this.eventMatches.toggleRow(match), 0);
          }
        });
      }
    }
  }

  // --------------------------------------------------------------------------
  private onMatchListUpdated = (matches: Match[]): void => {
    this.matches = matches.map(match => {
      let matchListMatch = Object.assign(new MatchListMatch(), match);

      matchListMatch.status = match.getPlayerStatus(this.currentProfile.sub);
      let playerJoinedCount = Object.keys(match.roleUserMapping).length;
      matchListMatch.playerCountSortCount = (match.playerCount * 100) + match.playerCount - playerJoinedCount;

      return matchListMatch;
    });
    if (!this.configService.isECCODeployment()) {
      this.updateEventMatchCount();
    }
    this.checkPublicMatches();
    if (!this.configService.isECCODeployment()) {
      this.checkEvents();

      // For every event with no match, add an empty match so the table will show the event
      Object.values(this.eventsById).forEach(event => {
        if (!!event.id && !this.matches.some(match => match.event == event.id)) {
          // Make an empty match so the table will show the event
          let m: Match = new Match();
          m.event = event.id;
          m.creationDate = event.creationDate;
          this.matches.push(m);
        }
        ;
      });

      // We have to give the table time to get setup before the custom sorting works it seems
      setTimeout(() => this.sortByCreationDates({
        data: this.matches,
        mode: this.eventMatches.sortMode,
        field: this.eventMatches.sortField,
        sortOrder: this.eventMatches.sortOrder,
        multiSortMeta: this.eventMatches.multiSortMeta
      } as SortEvent));
    }
  }

  // --------------------------------------------------------------------------
  private updateEventMatchCount(): void {
    this.matchCount = {};

    // set match count equal to zero for all events found in the match list
    // this step can't find any empty events
    Object.values(this.eventsById).forEach(event => {
      if (!!event.id) {
        if (!this.matchCount[event.id]) {
          this.matchCount[event.id] = 0;
        }
        this.matchCount[event.id] = this.matches.reduce((total: number, match: Match) => {
          if (event.id == match.event) {
            return total + 1;
          }
          return total;
        }, 0);
      }
    });
  }

  // --------------------------------------------------------------------------
  isMatchFull(match: Match): boolean {
    let playerJoinedCount = 0;
    Object.keys(match.roleUserMapping).forEach(role => {
      playerJoinedCount++;
    });

    return playerJoinedCount == match.playerCount;
  }

  // --------------------------------------------------------------------------
  getPlayerJoinedCountAsString(match: Match): string {
    let playerJoinedCount = 0;
    Object.keys(match.roleUserMapping).forEach(role => {
      playerJoinedCount++;
    });

    return playerJoinedCount.toString() + "/" + match.playerCount;
  }

  // --------------------------------------------------------------------------
  private checkPublicMatches(): void {
    this.noPublicMatches = !this.matches.find((match) => !match.event);
  }

  // --------------------------------------------------------------------------
  private checkEvents(): void {
    this.noEvents = Object.values(this.eventsById).length == 0;
  }

  // --------------------------------------------------------------------------
  private customEqualsFilter(value: string, filter: string): boolean {
    return this.customFilter(value, filter, this.doesStringEqualFilter);
  }

  // --------------------------------------------------------------------------
  private customContainsFilter(value: string, filter: string): boolean {
    return this.customFilter(value, filter, this.doesStringIncludeFilter);
  }

  // --------------------------------------------------------------------------
  private customFilter(value: string, filter: string, filterFunc: Function): boolean {
    if (!value) {
      return false;
    }

    // If our value is a game id, then instead find the game by id to filter by its name
    if (this.eventsById.hasOwnProperty(value)) {
      let event = this.eventsById[value];
      return filterFunc(event.name, filter) && filterFunc(event.name, this.searchFilter);
    }

    // If our value is a game id, then instead find the game by id to filter by its name
    if (this.gamesById.hasOwnProperty(value)) {
      let game = this.gamesById[value];
      return filterFunc(game.name, filter) && filterFunc(game.name, this.searchFilter);
    }

    // Otherwise, check properties on the match
    return filterFunc(value, filter) && filterFunc(value, this.searchFilter);
  }

  // --------------------------------------------------------------------------
  private doesStringEqualFilter(name: string, filter?: string): boolean {
    if (!filter?.trim()) {
      return true;
    }

    return name.trim().toLowerCase() == filter.trim().toLowerCase();
  }

  // --------------------------------------------------------------------------
  private doesStringIncludeFilter(name: string, filter?: string): boolean {
    if (!filter?.trim()) {
      return true;
    }

    return name.trim().toLowerCase().includes(filter.trim().toLowerCase());
  }
}
