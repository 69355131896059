// The type for all data needed for a particular role in a game
export type GameRole = {
  displayName: string,
  gameRole: string,
  required?: boolean
}

// Data structure to wrap what a Game is
// Examples include Follow the Money, CyberStrike, etc
export class Game {
  public id!: string;
  public name!: string;
  public description!: string;
  public image!: string;
  public thumbnail!: string;
  public screenshots!: string[];
  public minPlayers!: number;
  public maxPlayers!: number;
  public roles!: GameRole[];
  public active!: Boolean;
  public howToPlay!: String;
  public winningConditions!: String;
}
