<div class="p-component detail-root">
  <div class="standard-container detail-container">
    <div class="content-header">New Match</div>
    <form action="">
      <div class="content overflow-scroll-y">
        <div class="left-section">
          <div futuretech field class="subheader row full-width row-lines">
            <label>{{game.name}}</label>
          </div>

          <div futuretech field class="inputText row full-width row-lines">
            <label for="matchname">Match Name</label>
            <input pInputText type="text" name="matchname" id="matchname" [(ngModel)]="match.name" (ngModelChange)="updateDisabledReason()"/>
          </div>

          <div futuretech field class="dropDown row full-width row-lines">
            <label for="event">Add to Event</label>
            <p-dropdown name="event"  id="event" [options]="events" [(ngModel)]="match.event" (ngModelChange)="onEventChanged()" optionLabel="name" optionValue="id"></p-dropdown>
          </div>

          <div futuretech field class="switch row full-width row-lines">
            <label for="active">Enabled</label>
            <p-inputSwitch name="active" id="active" [(ngModel)]="match.enabled" label="-" title="Enable/Disable this match"></p-inputSwitch>
          </div>

          <div futuretech field class="inputNumber row full-width row-lines">
            <label for="playerCount">Number of Players</label>
            <p-inputNumber name="playerCount" id="playerCount" [(ngModel)]="match.playerCount" (ngModelChange)="onPlayerCountChanged()" [showButtons]="true" buttonLayout="horizontal" inputId="horizontal" spinnerMode="horizontal" [step]="1"
            incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" mode="decimal" [min]=game.minPlayers [max]=game.maxPlayers >
            </p-inputNumber>
          </div>
        </div>

        <div class="right-section">
          <div futuretech field class="subheader row full-width">
            <label>Players</label>
          </div>
          <add-player [event-id]="match.event" [profiles]="profiles" [roles]="availableRoles" [player-count]="match.playerCount" [role-user-mapping]="match.roleUserMapping" (onMapChange)="onRoleMappingChanged($event)"></add-player>
        </div>
      </div>

      <div class="content-footer padding-0-horizontal">
        <p-fieldset futuretech>
          <p-button (onClick)="create()" [disabled]="!!disabledReason">Create Match</p-button>
        </p-fieldset>
      </div>

      <div class="content-message"><span class="disabled-reason" *ngIf="disabledReason"><i class="pi pi-exclamation-triangle margin-2-horizontal"></i>{{disabledReason}}<i class="pi pi-exclamation-triangle margin-2-horizontal"></i></span></div>
    </form>
  </div>
</div>
