import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { MessageService } from 'primeng/api';
import { map, Observable } from 'rxjs';
import { ProfileService } from '../services/profile.service';
import { Profile } from '../util/profile';

// Route resolver for getting a list of all the Profiles
@Injectable({
  providedIn: 'root'
})
export class ProfileResolver implements Resolve<Profile[]> {
  // --------------------------------------------------------------------------
  constructor(
    private location: Location,
    private messageService: MessageService,
    private profileService: ProfileService
  ) { }

  // --------------------------------------------------------------------------
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Profile[]> {
    return this.profileService.getList().pipe(
      map((profiles: Profile[]) => {
        if (!!profiles) {
          return profiles;
        }
        this.messageService.add({
          severity: 'error', 
          detail: 'Problem getting the list of profiles',
          sticky: true
        });
        this.location.back();
        return [];
      })
    );
  }
}
