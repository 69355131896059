<div class="p-component detail-root">
  <div class="standard-container detail-container">
    <div class="content-header">Edit Match</div>
    <div class="content footer-L overflow-scroll-y">
      <div class="left-section">
        <div futuretech field class="subheader row full-width margin-1">
          <label>Match Details</label>
        </div>

        <p-fieldset futuretech class="match text-left dark">
          <button pButton pRipple class="margin-1 white" icon="pi pi-save" label="Save" title="Save Changes" (click)="update()" [disabled]="!needsSave()"></button>
          <button pButton pRipple class="margin-1 white" icon="pi pi-copy" label="Duplicate" title="Duplicate this Match" (click)="duplicate()"></button>
          <button pButton pRipple class="margin-1 white" icon="pi pi-trash" label="Delete" title="Delete this Match" (click)="delete()"></button>
        </p-fieldset>

        <div futuretech field class="inputText row full-width row-lines">
          <label>Match Name</label>
          <input pInputText type="text" [(ngModel)]="editedMatch.name" [class]="{'changed': changed(editedMatch.name, match.name)}" />
        </div>

        <div futuretech field class="readonly row full-width row-lines"
        *ngIf="!configService.isECCODeployment()">
          <label>Event</label>
          <span class="value">{{eventName}}</span>
        </div>

        <div futuretech field class="switch row full-width row-lines">
          <label>Enabled</label>
          <p-inputSwitch name="active" [(ngModel)]="editedMatch.enabled" label="Enabled" title="Enable/Disable this editedMatch" [class]="{'changed': changed(editedMatch.enabled, match.enabled)}"></p-inputSwitch>
        </div>

        <div futuretech field class="readonly row full-width row-lines">
          <label>Number of Players</label>
          <span class="value">{{editedMatch.playerCount}}</span>
        </div>
      </div>

      <div class="right-section">
        <div futuretech field class="subheader row full-width margin-1">
          <label>Players</label>
        </div>
        <joined-player
          [match]="match"
          [current-profile]="currentProfile"
          [profiles]="profiles"
          [roles]="roles"
          [available-roles]="availableRoles"
          [player-mappings]="match.roleUserMapping"
          (onJoin)="join($event)"
          (onLeave)="leave()" >
        </joined-player>
      </div>
    </div>

    <div class="content-footer flex height-L">
      <p-fieldset futuretech class="play" legend="Go to Match">
        <p-button class="margin-1" [disabled]="!canPlayMatch" (onClick)="play()">
          <span [class]="{'display-none': !match.isFinished(currentProfile.sub)}">View</span>
          <span [class]="{'display-none': match.isFinished(currentProfile.sub)}">Play</span>
        </p-button>
      </p-fieldset>
    </div>
  </div>
</div>
<p-confirmDialog header="Confirmation" icon="pi pi-trash"></p-confirmDialog>
