import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { MessageService } from 'primeng/api';
import { map, Observable } from 'rxjs';
import { GameService } from '../services/game.service';
import { Game } from '../util/game';

// Route resolver for getting a game using the ID that's part of the route
@Injectable({
  providedIn: 'root'
})
export class GameResolver implements Resolve<Game | null> {
  // --------------------------------------------------------------------------
  constructor(
    private router: Router,
    private messageService: MessageService,
    private gameService: GameService
  ) { }

  // --------------------------------------------------------------------------
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Game | null> {
    let gameId = route.paramMap.get('gid') || '';
    return this.gameService.get(gameId).pipe(
      map(game => {
        if (!!game) {
          return game;
        }
        this.messageService.add({
          severity: 'error', 
          detail: `Could not find game for id ${gameId}`,
          sticky: true
        });
        this.router.navigate(['/game']);
        return null;
      })
    );
  }
}
