import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[ngRepeat]'
})
export class RepeatDirective {
  @Input('ngRepeat') set amount (amount: number) {
		// loop from 0 to the requsted number of elements
    for (let i = 0; i < amount; i++) {
			// add element to the DOM
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  };

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef
  ) {}
}
