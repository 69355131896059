import { GameRole } from "./game";
import { UserPrivilege } from "./user-privilege";

// Data structure to wrap what a User is
// A User being the system level identifier and privileges
export class Util {
  // --------------------------------------------------------------------------
  static getAvaiableRoles(gameRoles: GameRole[], playerCount: number): GameRole[] {
    let requiredRoles: GameRole[] = [];
    let optionalRoles: GameRole[] = [];
    gameRoles.forEach(role => {
      if (role.required) {
        requiredRoles.push(role);
      }
      else {
        optionalRoles.push(role);
      }
    });

    // If we have any required roles, then make sure they are all filled before any optional roles get added
    if (requiredRoles.length > 0) {
      // If we still have open slots past our required roles, then add our optional
      // roles to the list of selectable roles
      while (playerCount > requiredRoles.length) {
        let optionalRole = optionalRoles.shift();
        if (!!optionalRole) {
          requiredRoles.push(optionalRole);
        }
        else {
          console.error('Game has an allowable player count higher than the number of roles');
          break;
        }
      }

      return requiredRoles;
    }

    // Otherwise, all optional roles are available
    return optionalRoles;
  }
}