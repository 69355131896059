import {Injectable} from '@angular/core';
import {from, mergeMap, Observable, of} from 'rxjs';

import {Auth, Hub} from 'aws-amplify';
import {CognitoUserPool, CognitoUserSession} from 'amazon-cognito-identity-js';
import {Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {WebsocketService} from './websocket.service';
import {UPDATE_PROFILES_SIGNAL} from './profile.service';

// Service used to both validate and set the current user and to get who is logged in elsewhere
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private identityToken?: string;
  private returnUrl?: string;

  // --------------------------------------------------------------------------
  constructor(
    private router: Router,
    private messageService: MessageService,
    private websocketService: WebsocketService
  ) {
    const listener = (data: any) => {
      switch (data.payload.event) {
        case 'configured':
          //console.log('the Auth module is configured');
          break;
        case 'signIn':
          this.websocketService.setEnabled(true);
          this.router.navigate([this.returnUrl || '/game']);
          break;
        case 'signIn_failure':
          //console.error('user sign in failed');
          break;
        case 'signUp':
          //console.log('user signed up');
          break;
        case 'signUp_failure':
          //console.error('user sign up failed');
          break;
        case 'confirmSignUp':
          this.websocketService.broadcast(UPDATE_PROFILES_SIGNAL);
          break;
        case 'completeNewPassword_failure':
          //console.error('user did not complete new password flow');
          break;
        case 'autoSignIn':
          //console.log('auto sign in successful');
          break;
        case 'autoSignIn_failure':
          //console.error('auto sign in failed');
          break;
        case 'forgotPassword':
          //console.log('password recovery initiated');
          break;
        case 'forgotPassword_failure':
          //console.error('password recovery failed');
          break;
        case 'forgotPasswordSubmit':
          this.router.navigate([this.returnUrl || '/game']);
          break;
        case 'forgotPasswordSubmit_failure':
          //console.error('password confirmation failed');
          break;
        case 'tokenRefresh':
          //console.log('token refresh succeeded');
          break;
        case 'tokenRefresh_failure':
          //console.error('token refresh failed');
          break;
        case 'cognitoHostedUI':
          //console.log('Cognito Hosted UI sign in successful');
          break;
        case 'cognitoHostedUI_failure':
          //console.error('Cognito Hosted UI sign in failed');
          break;
        case 'customOAuthState':
          //console.log('custom state returned from CognitoHosted UI');
          break;
        case 'customState_failure':
          // console.error('custom state failure');
          break;
        case 'parsingCallbackUrl':
          //console.log('Cognito Hosted UI OAuth url parsing initiated');
          break;
        case 'userDeleted':
          //console.log('user deletion successful');
          break;
        case 'signOut':
          this.websocketService.setEnabled(false);
          break;
      }
    };

    Hub.listen('auth', listener);
  }

  // --------------------------------------------------------------------------
  isLoggedIn(): Observable<boolean> {
    return from(Auth.currentSession()
      .then((data: CognitoUserSession) => {
        this.setIdentityToken(data.getIdToken().getJwtToken());
        let loggedIn = data && data.isValid();
        this.websocketService.setEnabled(loggedIn);
        return loggedIn;
      })
      .catch((err) => {
        return false;
      })
    );
  }

  // --------------------------------------------------------------------------
  setReturnUrl(url: string): void {
    this.returnUrl = url;
  }

  // --------------------------------------------------------------------------
  //Attemp to change the currently logged in user's password
  changePassword(newPassword: string, oldPassword: string): Observable<any> {
    return from(Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, oldPassword, newPassword);
      })
    );
  }

  // --------------------------------------------------------------------------
  logout(): Observable<any> {
    return from(Auth.signOut().catch(this.handleError.bind(this))).pipe(
      mergeMap(() => {
        this.identityToken = undefined;
        return of(null);
      })
    );
  }

  // --------------------------------------------------------------------------
  //Get the current user's "ID" value or undefined if not logged in
  getCurrentUserId(): Observable<string | undefined> {
    return from(Auth.currentAuthenticatedUser().catch(this.handleError.bind(this))).pipe(
      mergeMap(data => of(data.attributes.sub))
    )
  }

  // --------------------------------------------------------------------------
  //Get the current user's email or undefined if not logged in
  getCurrentUserEmail(): Observable<string | undefined> {
    return from(Auth.currentAuthenticatedUser().catch(this.handleError.bind(this))).pipe(
      mergeMap(data => of(data.attributes.emaed))
    )
  }

  // --------------------------------------------------------------------------
  //Get the current identity token string if defined
  getIdentityToken(): string | undefined {
    return this.identityToken;
  }

  // --------------------------------------------------------------------------
  private setIdentityToken(identityToken: string) {
    this.identityToken = identityToken;
  }

  // --------------------------------------------------------------------------
  private handleError(err: string): Observable<any> {
    this.messageService.add({
      severity: 'error',
      detail: `The authentication service gave us the following error: ${err}`,
      sticky: true
    });
    console.error(err);
    throw err;
  }
}
