import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { MatchService } from '../services/match.service';
import { Match } from '../util/match';

// Route resolver that gets the list of matches this user can see/edit/join
@Injectable({
  providedIn: 'root'
})
export class MatchListResolver implements Resolve<Match[]> {
  // --------------------------------------------------------------------------
  constructor(
    private matchService: MatchService
  ) { }

  // --------------------------------------------------------------------------
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Match[]> {
    return this.matchService.getList();
  }
}
