import { Injectable } from '@angular/core';
import { Observable, of, switchMap, tap } from 'rxjs';

import { AuthenticationService } from './authentication.service';
import { HTTPService } from './http.service';
import { ProfileService } from './profile.service';
import { WebsocketService } from './websocket.service';

import { Match } from '../util/match';
import { Profile } from '../util/profile';
import { FacilitationEvent } from '../util/facilitation-event';

// Service for gathering Matches from the server (both a full list and individually)
// UI components can register to listen for updates from websockets
@Injectable({
  providedIn: 'root'
})
export class MatchService {
  private listListeners: Function[] = [];
  private matchToDuplicate?: Match;
  private filterCriteriaColumnId?: string;
  private filterCriteriaFilterValue?: string;
  private cachedMatches?: Match[];

  // --------------------------------------------------------------------------
  constructor(
    private httpService: HTTPService,
    private authenticationService: AuthenticationService,
    private profileService: ProfileService,
    private websocketService: WebsocketService
  ) {
    this.websocketService.on('match-create', this.onMatchCreated);
    this.websocketService.on('match-update', this.onMatchUpdated);
    this.websocketService.on('match-delete', this.onMatchDeleted);
  }

  // --------------------------------------------------------------------------
  addListListener(cb: Function): void {
    this.listListeners.push(cb);
  }

  // --------------------------------------------------------------------------
  removeListListener(cb: Function): void {
    this.listListeners = this.listListeners.filter(listener => listener != cb);
  }

  // --------------------------------------------------------------------------
  clearCache(): void {
    delete this.cachedMatches;
  }

  // --------------------------------------------------------------------------
  getList(): Observable<Match[]> {
    if (!!this.cachedMatches) {
      return of(this.cachedMatches);
    }

    return this.httpService.get('match').pipe(
      switchMap((result: any[]) => of(result.map(event => this.serverToMatchObj(event)))),
      tap((matches: Match[]) => this.cachedMatches = matches)
    );
  }

  // --------------------------------------------------------------------------
  get(matchId: string): Observable<Match | undefined> {
    if (!!this.cachedMatches) {
      return of(this.cachedMatches.find(match => match.id == matchId));
    }

    return this.getList().pipe(
      switchMap(() => this.get(matchId))
    );
  }

  // --------------------------------------------------------------------------
  create(params: Match): Observable<any> {
    return this.httpService.post(`match`, params);
  }

  // --------------------------------------------------------------------------
  update(params: Match): Observable<any> {
    return this.httpService.put(`match`, params);
  }

  // --------------------------------------------------------------------------
  join(params: Match, role: string): Observable<any> {
    return this.httpService.put('match/join', { mid: params.id, role: role });
  }

  // --------------------------------------------------------------------------
  leave(params: Match): Observable<any> {
    return this.httpService.put('match/leave', params);
  }

  // --------------------------------------------------------------------------
  delete(params: Match): Observable<any> {
    return this.httpService.delete('match', params.id);
  }

  // --------------------------------------------------------------------------
  setMatchToDuplicate(match?: Match): void {
    this.matchToDuplicate = match;
  }

  // --------------------------------------------------------------------------
  getMatchToDuplicate(): Match | undefined {
    return this.matchToDuplicate;
  }

  // --------------------------------------------------------------------------
  setFilterCriteria(columnId: any, filterValue?: string): void {
    this.filterCriteriaColumnId = columnId;
    this.filterCriteriaFilterValue = filterValue;
  }

  // --------------------------------------------------------------------------
  getFilterCriteria(): { columnId: any, filterValue?: string } {
    return { columnId: this.filterCriteriaColumnId, filterValue: this.filterCriteriaFilterValue };
  }

  // --------------------------------------------------------------------------
  resetFilterCriteria(): void {
    this.filterCriteriaColumnId = '';
    this.filterCriteriaFilterValue = '';
  }

  // --------------------------------------------------------------------------
  play(params: Match): void {
    this.httpService.get('match/play', {id: params.id}).subscribe((result: any) => {
      window.open(result.redirectURL, '_blank');
    });
  }

  // --------------------------------------------------------------------------
  canUserSeeMatch(match: Match, user: Profile): boolean {
    // The match is valid for a user to see with the following cases:
    // The user is the creator
    return match.isCreator ||
    // The user is assigned to or has joined the match
    Object.values(match.roleUserMapping).includes(user.sub) ||
    // The user is not a guest account and the match is public and has open slots
    (!user.event && !match.event && !match.isFull()) ||
    // The match's event is the same as the guest user's event and the user is assigned to it
    (match.event == user.event && Object.values(match.roleUserMapping).includes(user.event));
  }

  // --------------------------------------------------------------------------
  doesUserHaveEventMatches(user: Profile, event: FacilitationEvent, matchToIgnore: Match): boolean {
    return !!this.cachedMatches && this.cachedMatches.some(match => match.id != matchToIgnore.id &&
      match.event == event.id && Object.values(match.roleUserMapping).some(userId => user.sub == userId));
  }

  // --------------------------------------------------------------------------
  // The WebSocket has gotten a signal that a match has been created
  private onMatchCreated = (match: any): void => {
      this.authenticationService.getCurrentUserId().pipe(
        switchMap((currentUserId?: string) => !!currentUserId ? this.profileService.get(currentUserId) : of(undefined)),
      )
      .subscribe((currentUser?: Profile) => {
        if (!!this.cachedMatches && !!currentUser) {
          // Set whether we are the creator of this match or not
          match.isCreator = match.creator == currentUser.sub;
          match = this.serverToMatchObj(match);
          if (this.canUserSeeMatch(match, currentUser)) {
            this.cachedMatches.push(match);
            this.updateListListeners();
          }
        }
      });
  }

  // --------------------------------------------------------------------------
  // The WebSocket has gotten a signal that a match has been updated
  private onMatchUpdated = (match: any): void => {
    if (!!this.cachedMatches) {
      this.authenticationService.getCurrentUserId()
      .subscribe((currentUserId?: string) => {
        if (!!this.cachedMatches && !!currentUserId) {
          let existingMatch: any = this.cachedMatches?.find(cachedMatch => cachedMatch.id == match.id);
          if (!!existingMatch) {
            // Update any attributes from the incoming match (it won't have ALL the match properties on it)
            for (let key in match) {
              if (existingMatch.hasOwnProperty(key)) {
                if (key != 'session') {
                  existingMatch[key] = match[key];
                }
                // Special case handling of the session field
                else {
                  for (let userId in match[key]) {
                    let incomingSession = match[key][userId];
                    existingMatch[key][userId] = {
                      state: incomingSession.state,
                      score: incomingSession.score,
                      outcome: incomingSession.outcome
                    };
                  }
                }
              }
            }

            // If the match is now full and we aren't part of it, remove it from our list
            // correction, unless this is an event match and the user is a facilitator...
            if (!existingMatch.event && existingMatch.isFull() && !existingMatch.isPlayer(currentUserId)) {
              this.cachedMatches = this.cachedMatches.filter(match => match.id != existingMatch.id);
            }
            this.updateListListeners();
          }
        }
      });
    }
  }

  // --------------------------------------------------------------------------
  // The WebSocket has gotten a signal that a match has been deleted
  private onMatchDeleted = (match: any): void => {
    if (!!this.cachedMatches) {
      this.cachedMatches = this.cachedMatches.filter(existingMatch => existingMatch.id != match.id);
      this.updateListListeners();
    }
  }

  // --------------------------------------------------------------------------
  private updateListListeners(): void {
    this.listListeners.forEach(listener => listener(this.cachedMatches));
  }

  // --------------------------------------------------------------------------
  private serverToMatchObj(serverObj: any): Match {
    return Match.clone(serverObj);
  }
}
