import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FacilitationEventService } from 'src/app/services/facilitation-event.service';
import { FacilitationEvent } from 'src/app/util/facilitation-event';
import { ConfirmationService } from 'primeng/api';
import { MatchService } from 'src/app/services/match.service';
import { LoadingService } from 'src/app/services/loading.service';

// UI for displaying a list of facilitation events
// The list is gathered by the route resolver, so we know
// if we get here, we have a valid list of items (even if it's empty)
@Component({
  selector: 'facilitation-event-list',
  templateUrl: './facilitation-event-list.component.html',
  styleUrls: ['./facilitation-event-list.component.scss'],
  providers: [ConfirmationService]
})
export class FacilitationEventListComponent implements OnDestroy {
  public events!: FacilitationEvent[];
  
  // --------------------------------------------------------------------------
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private facilitationEventService: FacilitationEventService,
    private matchService: MatchService,
    private confirmationService: ConfirmationService,
    private loadingService: LoadingService,
  ) {
    this.route.data.subscribe(({ events }) => {
      this.events = events;
    });

    this.facilitationEventService.addListListener(this.onEventListUpdated);
  }

  // --------------------------------------------------------------------------
  ngOnDestroy(): void {
    this.facilitationEventService.removeListListener(this.onEventListUpdated);
  }

  // --------------------------------------------------------------------------
  edit(event: FacilitationEvent): void {
    // Go to the event details page
    this.router.navigate([event.id], { relativeTo: this.route });
  }
  
  // --------------------------------------------------------------------------
  view(event: FacilitationEvent): void {
    this.matchService.setFilterCriteria('event', event.name);
    this.router.navigate(['match']);
  }

  // --------------------------------------------------------------------------
  delete(event: FacilitationEvent): void {
    this.loadingService.loading = true;
    this.facilitationEventService.delete(event).subscribe({
      next: () => {
        this.loadingService.loading = false;
      },
      error: () => {
        this.loadingService.loading = false;
      }
    });
  }

  // --------------------------------------------------------------------------
  new(): void {
    this.router.navigate(['new'], { relativeTo: this.route });
  }
   
  // --------------------------------------------------------------------------
  confirmDelete(event: FacilitationEvent): void {
    this.confirmationService.confirm({
      header: 'Delete Event?',
      message: `Are you sure that you want to delete event <br/><b>${event.name}</b>?`,
      accept: () => {
        //Actual logic to perform a confirmation
        this.delete(event)
      }
    });
  }

  // --------------------------------------------------------------------------
  private onEventListUpdated = (events: FacilitationEvent[]): void => {
    this.events = events;
  }
}
