import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

// Route guard to make sure a user is logged in before they are allowed to go to the route
@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {
  // --------------------------------------------------------------------------
  constructor(
    private authenticationService: AuthenticationService
  ) { }

  // --------------------------------------------------------------------------
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.authenticationService.isLoggedIn().pipe(
      switchMap((loggedIn:boolean) => {
        if (!!loggedIn) {
          return of(true);
        }
        this.authenticationService.setReturnUrl(state.url);
        return of(false);
      })
    );
  }
}
