import {Injectable} from '@angular/core';
import {Amplify} from 'aws-amplify';
import * as jsonData from '../../assets/cdk-outputs.json';

interface Config {

  // ApiUrl: string,
  // WebsocketUrl: string,
  // UserPool: string,
  // UserPoolWebClientId: string
  // UserPoolRegion: string
  ECCOUserPool: string,
  DeploymentUserPoolRegion: string,
  AssetsDomainName: string,
  ECCOUserPoolRegion: string,
  ApiUrl: string,
  WebsocketUrl: string,
  ECCOUserPoolWebClientId: string,
  DeploymentUserPoolWebClientId: string,
  HostedZoneId: string,
  DeploymentUserPool: string

}

//This service is used to hold information regarding AWS Web Services. The data
//comes from the ./src/assets/cdk-outputs.json file.
@Injectable({
  providedIn: 'root'
})
export class AwsConfigService {
  public data: {
    // ApiUrl?: string,
    // WebsocketUrl?: string,
    // UserPoolId?: string,
    // UserPoolWebClientId?: string
    // UserPoolRegion?: string
    UserPoolRegion?: string,
    AssetsDomainName?: string,
    ApiUrl?: string,
    WebsocketUrl?: string,
    UserPoolWebClientId?: string,
    HostedZoneId?: string,
    UserPoolId?: string
  };

  //---------------------------------------------------------------------------
  constructor() {
    this.data = {};
    const config_item_key = Object.keys(jsonData).find((k) => k.endsWith("config")) || ""
    // @ts-ignore
    let config = jsonData[config_item_key] as Config;

    this.data.ApiUrl = (config.ApiUrl.endsWith("/")) ?
      config.ApiUrl :
      config.ApiUrl + "/"

    console.debug(JSON.stringify(config,null,1))
    this.data.WebsocketUrl = config.WebsocketUrl;
    this.data.UserPoolId = config.ECCOUserPool || config.DeploymentUserPool;
    this.data.UserPoolWebClientId = config.ECCOUserPoolWebClientId || config.DeploymentUserPoolWebClientId;
    this.data.UserPoolRegion = config.ECCOUserPoolRegion || config.DeploymentUserPoolRegion;
    console.debug(JSON.stringify(this.data,null,1))

  }

  //---------------------------------------------------------------------------
  public Configure() {
      Amplify.configure({
        Auth: {
          region: this.data.UserPoolRegion,
          userPoolId: this.data.UserPoolId,
          userPoolWebClientId: this.data.UserPoolWebClientId
        }
      })
    }


  //----------------------------------------------------------------------------
  public isECCODeployment(): boolean {
    return this.data.UserPoolRegion!.startsWith("us-gov") || false
  }

  //----------------------------------------------------------------------------
  //Get the Url of the AWS API Gateway
  public ApiUrl(): string | undefined {
    return this.data.ApiUrl;
  }

  //----------------------------------------------------------------------------
  //Get the Url of the AWS Websocket API
  public WebsocketUrl(): string | undefined {
    return this.data.WebsocketUrl;
  }
}
