import { Profile } from "./profile";

// Data structure to wrap what a Facilitation Event is
// A Facilitation Event wraps some numbers of Matches
// some number of Users for easy setup of Matches for
// facilitators
export class FacilitationEvent {
  public id?: string;
  public name: string = '';
  public description?: string;
  public isCreator?: boolean;
  public creationDate: number = Date.now();

  // --------------------------------------------------------------------------
  static clone(other: FacilitationEvent): FacilitationEvent {
    let clone = new FacilitationEvent();
    clone.id = other.id;
    clone.name = other.name;
    clone.description = other.description;
    clone.isCreator = other.isCreator;
    clone.creationDate = other.creationDate;
    return clone;
  }

  // --------------------------------------------------------------------------
  getGuestUsername(guestAccount: Profile): string {
    return `${this.name.replaceAll(' ', '')}-${guestAccount.displayName.replaceAll(' ', '')}`.toLowerCase();
  }
}
