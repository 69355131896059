<div id="mini-match-list">
  <div class="section-header margin-2-vertical">Match List</div>

  <p futuretech  mini-match-list *ngIf="!event.id">All guest accounts to be used in this event must be created <b>and saved</b> before matches can safely be created.  After creating your users and clicking on the create button, you can then edit this event to create matches.</p>
  <p-table
      futuretech
      mini-match-list
      #dt
      *ngIf="!!event.id"
      [value]="value"
      dataKey="id"
      sortField="creationDate"
      rowGroupMode="subheader"
      groupRowsBy="creationDate"
      [scrollable]="true">

    <ng-template pTemplate="caption">
      <div class="text-center">
        <p-button pRipple type="button" class="block" label="Match" icon="pi pi-plus"
                  (onClick)="openCreateDialog()"></p-button>
      </div>
    </ng-template>

    <ng-template pTemplate="groupheader" let-match let-rowIndex="rowIndex" let-expanded="expanded">

      <tr class="row-group-header match" [class]="{'locked-match': match.event, 'unlocked-match': !match.event}">
        <td colspan="8" class="row-group-header">
          <ng-container *ngIf="!match.event">
            <div class="match-icon"
                 title="This match is pending and will be created when the event is updated">
              <span>Pending</span>
            </div>
          </ng-container>

          <div futuretech field class="collapsed-match">
            <div class="properties">
              <div class="icon"><img src="{{gamesById[match.game].image}}" alt=""></div>

              <div class="property locked-match-name" *ngIf="match.event">
                <p-inplace mini-match-list [active]="activeMatchNameEdits[match.id]"
                           (onActivate)="activeMatchNameEdits[match.id] = true">
                  <ng-template pTemplate="display">
                    {{ match.name }}
                    <i class="pi pi-pencil margin-2-horizontal"></i>
                  </ng-template>
                  <ng-template pTemplate="content">
                    <input pInputText type="text" name="name" [(ngModel)]="match.name"
                           (ngModelChange)="onMatchAttributeChanged()"
                           (blur)="activeMatchNameEdits[match.id] = false"
                           (keyup.enter)="activeMatchNameEdits[match.id] = false"/>
                  </ng-template>
                </p-inplace>
              </div>

              <div class="property unlocked-match-name" *ngIf="!match.event">
                                <span class="cursor-pointer" (click)="edit(match, rowIndex)"
                                      title="Edit this match">{{ match.name }}<i
                                    class="pi pi-pencil margin-2-horizontal"></i></span>
              </div>

              <div class="property game-name">
                <span>{{ gamesById[match.game].name }}</span>
              </div>

            </div>
            <div *ngIf="match.playerCount == 1" class="arrow-button cursor-pointer" [pRowToggler]="match">
              View Assigned Player<i *ngIf="!expanded" class="pi pi-chevron-down margin-2-horizontal"></i><i
                *ngIf="expanded" class="pi pi-chevron-up margin-2-horizontal"></i></div>
            <div *ngIf="match.playerCount != 1" class="arrow-button cursor-pointer" [pRowToggler]="match">
              View {{ match.playerCount }} Assigned Players<i *ngIf="!expanded"
                                                              class="pi pi-chevron-down margin-2-horizontal"></i><i
                *ngIf="expanded" class="pi pi-chevron-up margin-2-horizontal"></i></div>

            <p-inputSwitch class="enable vertical" name="active" [(ngModel)]="match.enabled"
                           (ngModelChange)="onMatchAttributeChanged()" label="-"
                           title="Enable/Disable this match"></p-inputSwitch>
            <button type="button" pButton pRipple
                    class="p-button-text p-button-rounded p-button-plain mr-2 inline-block duplicate-match"
                    icon="pi pi-copy" (click)="duplicate(match)" title="Duplicate this match"></button>
            <button type="button" pButton pRipple
                    class="p-button-text p-button-rounded p-button-plain mr-2 inline-block delete-match"
                    icon="pi pi-trash" (click)="delete(match)" title="Delete this match"></button>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-match let-rowIndex="rowIndex">
      <tr class="full-extent">
        <td class="full-table" col-span="8">
          <joined-player mini-match-list [match]="match" [profiles]="profiles"
                         [roles]="gamesById[match.game].roles" [available-roles]="[]"
                         [player-mappings]="match.roleUserMapping"></joined-player>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
