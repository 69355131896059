<div class="p-component detail-root">
    <div class="standard-container detail-container">
        <h1>{{ game.name }}</h1>

        <div class="game-screenshots">
            <p-carousel futuretech game-screenshots [value]="game.screenshots" [numVisible]="3"
                        [responsiveOptions]="responsiveOptions">
                <ng-template let-screenshot pTemplate="item" let-rowIndex="rowIndex">
                    <ng-container *ngIf="isScreenshotOfAnyType(screenshot, ['jpg','png','gif','jpeg'])">
                        <p-image futuretech src="{{screenshot}}" alt="" appendTo="body" [preview]="true"></p-image>
                    </ng-container>
                    <ng-container *ngIf="isScreenshotOfType(screenshot, 'mp4')">
                        <video width="90%" height="100%" controls>
                            <source src="{{screenshot}}" type="video/mp4">
                        </video>
                    </ng-container>
                </ng-template>
            </p-carousel>

        </div>

        <div>
            <ng-container *ngIf="!currentProfile.isGuest()">
                <p-button class="margin-1" (onClick)="new()">New Match</p-button>
            </ng-container>
            <p-button class="margin-1" (onClick)="view()">View Matches</p-button>
        </div>
        <div class="description">
            <h3>About</h3>
            <p class="players"><b>Number of Players: </b>{{ game.minPlayers }} to {{ game.maxPlayers }}</p>
            <p>{{ game.description }}</p>
            <h3>Winning Conditions</h3>
            <p>{{ game.winningConditions }}</p>
            <h3>How to Play</h3>
            <p>{{ game.howToPlay }}</p>
        </div>
        <!--        <div class="content-footer">-->
    </div>
</div>
