import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { GameRole } from 'src/app/util/game';
import { RoleUserMapping } from 'src/app/util/match';
import { Profile } from 'src/app/util/profile';

type Player = { availableRoles: GameRole[], role: string, profile: Profile };

// UI for getting the information needed for logging in a registered user
@Component({
  selector: 'add-player',
  templateUrl: './add-player.component.html',
  styleUrls: ['./add-player.component.scss']
})
export class AddPlayerComponent implements OnInit {
  @Input('event-id') eventId?: string;
  @Input() set profiles (profiles: Profile[]) {
      this._profiles = profiles;
      this.players.forEach(player => {
        let profile = profiles.find(profile => profile.sub == player.profile.sub);
        // If the profile got updated, then reset the profile in our player
        if (!!profile) {
          player.profile = this.formatProfile(profile);
        }
        // Otherwise the profile got removed, so clear out the profile for our player
        else {
          player.profile = new Profile();
        }
      });
    };
    get profiles(): Profile[] { return this._profiles; }
  @Input() set roles (roles: GameRole[]) {
    this._roles = roles;
    this.availableRoles = [...roles];
    this.players?.forEach(player => {
      let playerRole = this.availableRoles.shift();
      if (!!playerRole) {
        player.role = playerRole.gameRole;
        player.availableRoles = [];
      }
    });
    this.updateRoleUserMapping();
  };
  @Input('role-user-mapping') set roleUserMapping(roleUserMapping: RoleUserMapping) {
    // If we haven't been initialized yet, then set our player values based on the incoming mappings
    if (!this.initialized) {
      Object.keys(roleUserMapping).forEach((role: string, index: number) => {
        let player = this.players[index];
        let profile = this.profiles.find(profile => profile.sub == roleUserMapping[role]);
        if (!!profile) {
          player.role = role;
          player.profile = this.formatProfile(profile);
        }
      });
    }
    // Otherwise, update our players to match our role mappings in case our parent component changed
    // the mappings without our knowing
    else {
      this.players.forEach(player => {
        if (!!player.profile && !roleUserMapping[player.role]) {
          player.profile = new Profile();
        }
      });
    }
  };
  @Input('player-count') set playerCount(playerCount: number) {
    // If we have more players than assigned roles, assign more roles
    while (this.players.length < playerCount) {
      let playerRole = this.availableRoles.shift();
      if (!!playerRole) {
        this.players.push({ availableRoles: [], role: playerRole.gameRole, profile: new Profile() });
      }
      else {
        // This error should never happen unless the game is setup wrong in the database
        this.messageService.add({
          severity: 'error',
          detail: 'Not enough roles for current player count',
          sticky: true
        })
      }
    }

    // If we have more assigned roles then players, remove some assigned roles
    while (this.players.length > playerCount) {
      let removedPlayer = this.players.pop();
      if (!!removedPlayer) {
        this.availableRoles = this.addAvailableRole(this.availableRoles, removedPlayer.role);
      }
    }
    this.updateRoleUserMapping();
  };
  @Output() onMapChange: EventEmitter<RoleUserMapping> = new EventEmitter();
  public players: Player[] = [];
  public potentialUsers: Profile[] = [];
  private availableRoles!: GameRole[];
  private initialized: boolean = false;
  private _roles!: GameRole[];
  private _profiles!: Profile[];

  // --------------------------------------------------------------------------
  constructor(
    private messageService: MessageService
  ) {}

  // --------------------------------------------------------------------------
  ngOnInit(): void {
    this.initialized = true;
  }

  // --------------------------------------------------------------------------
  clear(player: Player): void {
    player.profile = new Profile();
    this.updateRoleUserMapping();
  }

  // --------------------------------------------------------------------------
  onRoleChanged(player: Player, newRole: string): void {
    if (player.role != newRole) {
      // Update which roles are available to switch to for all the players
      this.availableRoles = this.addAvailableRole(this.availableRoles.filter(role => role.gameRole != newRole), player.role);

      // Actually update our player object
      player.role = newRole;

      this.updateRoleUserMapping();
    }
  }

  // --------------------------------------------------------------------------
  updateRoleUserMapping(): void {
    let mapping: RoleUserMapping = {};
    this.players.forEach(player => {
      // Make sure the available roles are set correctly
      player.availableRoles = this.addAvailableRole([], player.role).concat(this.availableRoles);

      if (!!player.profile && !!player.profile.sub) {
        // Add this player to our map
        mapping[player.role] = player.profile.sub;
      }
    });

    // If we've already been initialized, then this is a UI update, so notify
    // our parent component to change its data
    if (this.initialized) {
      this.onMapChange.emit(mapping);
    }
  }

  // --------------------------------------------------------------------------
  filterUsers(filter: string): void {
    // Get the profiles that fit the filter by email address or display name and aren't assigned to other roles
    // Filter out guest profiles that aren't associated with the current event selected for the match
    this.potentialUsers = this.profiles.filter(profile => (profile.displayName.includes(filter) || profile.email.includes(filter)) &&
      this.players.every(player => player.profile.sub != profile.sub) && (!profile.event || profile.event == this.eventId))
    // Modify the display name to include what type of account this profile is
    .map(profile => this.formatProfile(profile));
  }

  // --------------------------------------------------------------------------
  private addAvailableRole(roleList: GameRole[], gameRole: string): GameRole[] {
    let role = this._roles.find(role => role.gameRole == gameRole);
    if (!!role) {
      roleList.push(role);
    }
    return [...roleList];
  }

  // --------------------------------------------------------------------------
  private formatProfile(profile: Profile): Profile {
    let clone = new Profile();
    clone.displayName = `${profile.displayName} (${profile.type})`;
    clone.email = profile.email;
    clone.iconURL = profile.iconURL;
    clone.sub = profile.sub;
    return clone;
  }
}
