import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GameRole } from 'src/app/util/game';
import { Match } from 'src/app/util/match';
import { Profile } from 'src/app/util/profile';

// UI for displaying the details for one particular match
// The match in question is gathered from the route resolver, so we know
// it exists if we got here
@Component({
  selector: 'match-view',
  templateUrl: './match-view.component.html',
  styleUrls: ['../match-detail/match-detail.component.scss']
})
export class MatchViewComponent {
  @Input() match!: Match;
  @Input('event-name') eventName!: string;
  @Input() profiles!: Profile[];
  @Input('current-profile') currentProfile!: Profile;
  @Input() roles!: GameRole[];
  @Input('available-roles') availableRoles!: GameRole[];
  @Input('can-play-match') canPlayMatch!: boolean;
  @Output() onJoin: EventEmitter<string> = new EventEmitter();
  @Output() onLeave: EventEmitter<void> = new EventEmitter();
  @Output() onPlay: EventEmitter<void> = new EventEmitter();
  
  // --------------------------------------------------------------------------
  join(role: string): void {
    this.onJoin.emit(role);
  }

  // --------------------------------------------------------------------------
  leave(): void {
    this.onLeave.emit();
  }

  // --------------------------------------------------------------------------
  play(): void {
    this.onPlay.emit();
  }
}
