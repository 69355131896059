import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { FacilitationEventService } from '../services/facilitation-event.service';
import { FacilitationEvent } from '../util/facilitation-event';

// Route resolver that gets the list of facilitation events this user can see/edit
@Injectable({
  providedIn: 'root'
})
export class FacilitationEventListResolver implements Resolve<FacilitationEvent[]> {
  // --------------------------------------------------------------------------
  constructor(
    private facilitationEventService: FacilitationEventService
  ) { }

  // --------------------------------------------------------------------------
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<FacilitationEvent[]> {
    return this.facilitationEventService.getList();
  }
}
