import {Game} from "./game";

export type RoleUserMapping = { [role: string]: string | undefined }

enum PlayerState {
  Attention = 'ATTN',
  Pending = 'PEND',
  Over = 'OVER'
}

enum Outcome {
  Win = 'WIN',
  Lose = 'LOSE',
  Forfeit = 'FORFEIT',
  Draw = 'DRAW',
  NA = 'NA',
  None = 'NONE',
  Scored = 'USE_SCORES'
}

type Session = { [userId: string]: { state: PlayerState, score: number, outcome: Outcome } };

export class PlayerInfo {
  userId!: string;
  nickname!: string;
  email!: string;
  position?: string;
  host: boolean = false;

  // --------------------------------------------------------------------------
  static clone(other: PlayerInfo) {
    const clone = new PlayerInfo();
    clone.userId = other.userId;
    clone.nickname = other.nickname;
    clone.email = other.email;
    clone.position = other.position;
    clone.host = other.host;
    return clone;
  }
}

// Data structure to wrap what a Match is
// A Match being one playable instance of a Game
export class Match {
  public id?: string;
  public game!: string;
  public name?: string;
  public event?: string;
  public enabled: boolean = true;
  public playerCount: number = 0;
  public isCreator!: boolean;
  public roleUserMapping: RoleUserMapping = {};
  public gid?: string;
  public session?: Session; // game session updates from the Game Server
  public creationDate: number = Date.now();
  public playerInfo: PlayerInfo[] = []

  // --------------------------------------------------------------------------
  static clone(other: Match): Match {
    const clone = new Match();
    clone.id = other.id;
    clone.game = other.game;
    clone.name = other.name;
    clone.event = other.event;
    clone.enabled = other.enabled;
    clone.playerCount = other.playerCount;
    clone.isCreator = other.isCreator;
    clone.roleUserMapping = other.roleUserMapping;
    clone.gid = other.gid;
    clone.session = other.session;
    clone.creationDate = other.creationDate;
    clone.playerInfo = other.playerInfo.map((info) => PlayerInfo.clone(info));
    return clone;
  }

  // --------------------------------------------------------------------------
  static duplicate(other: Match): Match {
    const duplicate = new Match();
    duplicate.game = other.game;
    duplicate.name = `Copy of ${other.name}`;
    duplicate.event = other.event;
    duplicate.enabled = other.enabled;
    duplicate.playerCount = other.playerCount;
    duplicate.roleUserMapping = other.roleUserMapping;
    duplicate.playerInfo = other.playerInfo;
    return duplicate;
  }

  // --------------------------------------------------------------------------
  equals(other: Match): boolean {
    return this.id == other.id &&
      this.game == other.game &&
      this.name == other.name &&
      this.event == other.event &&
      this.enabled == other.enabled &&
      this.playerCount == other.playerCount &&
      this.isCreator == other.isCreator &&
      Object.keys(this.roleUserMapping).every(role => this.roleUserMapping[role] == other.roleUserMapping[role]) &&
      this.gid == other.gid &&
      this.creationDate == other.creationDate;
  }

  // --------------------------------------------------------------------------
  isPlayer(userId?: string): boolean {
    return !!userId && Object.values(this.roleUserMapping).includes(userId);
  }

  // --------------------------------------------------------------------------
  isFull(): boolean {
    return Object.keys(this.roleUserMapping).length == this.playerCount;
  }

  // --------------------------------------------------------------------------
  setGame(game: Game): void {
    this.game = game.id;
    this.roleUserMapping = {};

    // Make sure the player count is within the range of the game
    if (this.playerCount < game.minPlayers) {
      this.playerCount = game.minPlayers;
    }
    if (this.playerCount > game.maxPlayers) {
      this.playerCount = game.maxPlayers;
    }
  }

  // --------------------------------------------------------------------------
  isFinished(userId: string): boolean {
    if (!!this.session && this.session.hasOwnProperty(userId)) {
      let outcome = this.session[userId].outcome;
      return outcome != Outcome.NA && outcome != Outcome.None;
    }
    return false;
  }

  // --------------------------------------------------------------------------
  getPlayerStatus(userId: string): string {
    if (!this.enabled) {
      return 'Match Disabled';
    }
    if (!this.isFull()) {
      return 'Waiting for Players';
    }
    if (!this.session) {
      return 'Game Not Created';
    } else if (this.session.hasOwnProperty(userId)) {
      let playerState = this.session[userId];
      switch (playerState.outcome) {
        case Outcome.Win:
          return 'You Win!';
        case Outcome.Lose:
          return 'You Lost';
        case Outcome.Forfeit:
          return 'Forfeit';
        case Outcome.Draw:
          return 'Draw';
        case Outcome.Scored:
          return `Final Score: ${playerState.score}`;
      }

      if (playerState.state == PlayerState.Pending) {
        return 'Not Your Turn';
      }
    }
    if (!this.isPlayer(userId)) {
      return 'Not A Player';
    }
    return 'Ready To Play';
  }
}
