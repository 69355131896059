import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { map, Observable, of, switchMap } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { ProfileService } from '../services/profile.service';
import { Profile } from '../util/profile';

@Injectable({
  providedIn: 'root'
})
export class CurrentProfileResolver implements Resolve<Profile> {
  // --------------------------------------------------------------------------
  constructor(
    private authenticationService: AuthenticationService,
    private profileService: ProfileService
  ) { }

  // --------------------------------------------------------------------------
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Profile> {
    return this.authenticationService.getCurrentUserId().pipe(
      switchMap((userID: string|undefined) => {
        if (!!userID) {
          return this.profileService.get(userID).pipe(
            map(profile => {
              if (!!profile) {
                return profile;
              }
              return new Profile();
            })
          );
        }
        else {
          return of(new Profile());
        }
      })
    );
  }

}
