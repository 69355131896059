import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatchService } from 'src/app/services/match.service';
import { Match, RoleUserMapping } from 'src/app/util/match';
import { Game, GameRole } from 'src/app/util/game';
import { FacilitationEventService } from 'src/app/services/facilitation-event.service';
import { FacilitationEvent } from 'src/app/util/facilitation-event';
import { Profile } from 'src/app/util/profile';
import { LoadingService } from 'src/app/services/loading.service';
import { ProfileService } from 'src/app/services/profile.service';
import { Util } from 'src/app/util/util';

// UI for gathering all information needed for creating a new match
@Component({
  selector: 'match-new',
  templateUrl: './match-new.component.html',
  styleUrls: ['./match-new.component.scss']
})
export class MatchNewComponent implements OnDestroy {
  public match: Match = new Match();
  public game!: Game;
  public events: FacilitationEvent[] = [{ name: 'None' } as FacilitationEvent];
  public ctrlDown: boolean = false;
  public profiles!: Profile[]; ///A list of all the known user Profiles
  public disabledReason?: string;
  public availableRoles!: GameRole[];

  // --------------------------------------------------------------------------
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private matchService: MatchService,
    private facilitationEventService: FacilitationEventService,
    private profileService: ProfileService,
    private loadingService: LoadingService,
  ) {
    this.route.data.subscribe(({ events, game, profiles }) => {
      this.events = this.events.concat(events);
      this.game = game;
      this.profiles = profiles;

      // See if there's a match we want to duplicate
      let matchToDuplicate = this.matchService.getMatchToDuplicate();
      // If we don't have a match to duplicate, then set up this match's default values
      if (!matchToDuplicate) {
        this.match.setGame(this.game);
      }
      // Otherwise, duplicate the match minus the id
      else {
        this.match = Match.duplicate(matchToDuplicate);
        // Clear out the match we want to duplicate
        this.matchService.setMatchToDuplicate();
      }
      this.updateAvailableRoles();
      this.updateDisabledReason();
    });

    this.facilitationEventService.addListListener(this.onEventListUpdated);
    this.profileService.addListListener(this.onProfilesUpdated);
  }

  // --------------------------------------------------------------------------
  ngOnDestroy(): void {
    this.facilitationEventService.removeListListener(this.onEventListUpdated);
    this.profileService.removeListListener(this.onProfilesUpdated);
  }

  // --------------------------------------------------------------------------
  create(): void {
    if (!this.disabledReason) {
      this.loadingService.loading = true;
      this.matchService.create(this.match).subscribe({
        next: () => {
          this.router.navigateByUrl('/match');
        },
        error: () => {
          this.loadingService.loading = false;
        }
      });
    }
  }

  // --------------------------------------------------------------------------
  onEventChanged(): void {
    // Wipe out any user role mappings for guest accounts since guest accounts are tied to one specific event
    let roles = Object.keys(this.match.roleUserMapping);
    roles.forEach(role => {
      let profile = this.profiles.find(profile => profile.sub == this.match.roleUserMapping[role]);
      if (!!profile?.event && profile.event != this.match.event) {
        delete this.match.roleUserMapping[role];
      }
    });

    // If we had any updates, reassign our roleUserMapping object to trigger
    // Angular changes in our components
    if (roles.length != Object.keys(this.match.roleUserMapping).length) {
      this.match.roleUserMapping = { ...this.match.roleUserMapping };
    }

    this.updateDisabledReason();
  }

  // --------------------------------------------------------------------------
  onPlayerCountChanged(): void {
    this.updateAvailableRoles();
    this.updateDisabledReason();
  }

  // --------------------------------------------------------------------------
  onRoleMappingChanged(roleUserMapping: RoleUserMapping): void {
    this.match.roleUserMapping = roleUserMapping;
    this.updateDisabledReason();
  }

  // --------------------------------------------------------------------------
  updateDisabledReason(): void {
    // Don't bother checking a reason we can't create/update a match while it's
    // processing a previous operation
    if (!this.loadingService.loading) {
      if (!this.match.name) {
        this.disabledReason = 'Match needs a name';
      }
      else if (this.match.playerCount == 0) {
        this.disabledReason = 'Match needs at least one player';
      }
      else if (!!this.match.event && !this.match.isFull()) {
        this.disabledReason = "Matches assigned to events can't have open player slots";
      }
      else {
        this.disabledReason = undefined;
      }
    }
  }

  // --------------------------------------------------------------------------
  private updateAvailableRoles(): void {
    this.availableRoles = Util.getAvaiableRoles(this.game.roles, this.match.playerCount);
  }

  // --------------------------------------------------------------------------
  private onEventListUpdated = (events: FacilitationEvent[]): void => {
    this.events = [{ name: 'None' } as FacilitationEvent].concat(events);
  }

  // --------------------------------------------------------------------------
  private onProfilesUpdated = (profiles: Profile[]): void => {
    this.profiles = profiles;
    // Trigger our add player component to update its internal data
    this.match.roleUserMapping = { ...this.match.roleUserMapping };
  }
}
