import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import {AwsConfigService} from "../../services/aws-config.service";

// UI for getting the information needed for logging in a registered user
@Component({
  selector: 'header-navigation',
  templateUrl: './header-navigation.component.html',
  styleUrls: ['./header-navigation.component.scss']
})
export class HeaderNavigationComponent implements OnInit, OnDestroy {
  public label?: string;
  public route?: string;
  public hideBackButton: boolean = false;
  private routeSubscription!: Subscription;

  // --------------------------------------------------------------------------
  constructor(
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private configService: AwsConfigService
    ) {};

  // --------------------------------------------------------------------------
  ngOnInit(): void {
    this.routeSubscription = this.activatedRoute.data.subscribe((params: any) => {
      this.label = params.label;
      // cough
      if(params.label === "Matches & Events" && this.configService.isECCODeployment()) {
        this.label = "Matches"
      }
      this.route = params.route;
      this.hideBackButton = params.hideBackButton;
    });
  }

  // --------------------------------------------------------------------------
  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
  }

  // --------------------------------------------------------------------------
  gotoRoute() : void {
    if (!!this.route) {
      this.router.navigate([this.route]);
    }
    else {
      this.location.back();
    }
  }
}
