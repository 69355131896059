import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Game } from 'src/app/util/game';
import { MatchService } from 'src/app/services/match.service';
import { Profile } from 'src/app/util/profile';

// UI for displaying the details for one particular game
// The game in question is gathered from the route resolver, so we know
// it exists if we got here
@Component({
  selector: 'game-detail',
  templateUrl: './game-detail.component.html',
  styleUrls: ['./game-detail.component.scss']
})
export class GameDetailComponent {
  public currentProfile!: Profile;
  public game!: Game;
  
  public responsiveOptions = [
    {
      breakpoint: '1920px',
      numVisible: 3,
      numScroll: 3
    },
    {
      breakpoint: '1500px',
      numVisible: 2,
      numScroll: 2
    },
    {
      breakpoint: '1280px',
      numVisible: 1,
      numScroll: 1
    }
  ];

  // --------------------------------------------------------------------------
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private matchService: MatchService
  ) {
    this.activatedRoute.data.subscribe(({ currentProfile, game }) => {
      this.currentProfile = currentProfile;
      this.game = game;
    });
  }

  // --------------------------------------------------------------------------
  new(): void {
    this.router.navigate([`match/new/${this.game.id}`]);
  }
  
  // --------------------------------------------------------------------------
  view(): void {
    this.matchService.setFilterCriteria('game', this.game.name);
    this.router.navigate(['match']);
  }
  
  // --------------------------------------------------------------------------
  isScreenshotOfType(screenshot: string, extension: string) : boolean {
    return screenshot.endsWith(`.${extension}`);
  }
  
  // --------------------------------------------------------------------------
  isScreenshotOfAnyType(screenshot: string, extensions: string[]) : boolean {
    return extensions.some(extension => this.isScreenshotOfType(screenshot, extension));
  }
}
