import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { MessageService } from 'primeng/api';
import { map, Observable } from 'rxjs';
import { FacilitationEventService } from '../services/facilitation-event.service';
import { FacilitationEvent } from '../util/facilitation-event';

// Route resolver for getting a facilitation event using the ID that's part of the route
@Injectable({
  providedIn: 'root'
})
export class FacilitationEventResolver implements Resolve<FacilitationEvent | null> {
  // --------------------------------------------------------------------------
  constructor(
    private router: Router,
    private messageService: MessageService,
    private facilitationEventService: FacilitationEventService
  ) { }

  // --------------------------------------------------------------------------
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<FacilitationEvent | null> {
    let eventId = route.paramMap.get('id') || '';
    return this.facilitationEventService.get(eventId).pipe(
      map(event => {
        if (!!event) {
          return event;
        }
        this.messageService.add({
          severity: 'error', 
          detail: `Could not find event for id ${eventId}`,
          sticky: true
        });
        this.router.navigate(['/event']);
        return null;
      })
    );
  }
}
