<p-table futuretech joined-player [value]="players" class="small-header">
  <ng-template pTemplate="header">
    <tr>
      <th>Role</th>
      <th>Player</th>
      <th class="status">Status</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-player>
    <tr class="joined-player">
      <td>{{player.role}}</td>
      <td class="player"><span class="name">{{player.displayName}}</span> <span class="type">({{player.type}})</span></td>
      <td class="status"><span class="type" *ngIf="!!match.session">{{match.getPlayerStatus(player.userId)}}</span></td>
    </tr>
  </ng-template>
  <ng-template pTemplate="summary" *ngIf="canJoinMatch || openSlots > 0">
    <ng-container *ngIf="canJoinMatch">
      <div futuretech class="joined-player can-join-is-creator row full-width row-lines">
        <div class="check hidden margin-2" style="width: 2rem"><p-checkbox class="check"></p-checkbox></div>
        <div class="role margin-2"><div class="inner-text"><p-dropdown class="role" [options]="availableRoles" [(ngModel)]="joiningRole" optionLabel="displayName" optionValue="gameRole" appendTo="body"></p-dropdown></div></div>
        <div class="player margin-2"><button pButton pRipple type="button" class="join-match" label="Join" (click)="join()"></button></div>
        <button pButton pRipple type="button" icon="pi pi-copy" class="p-button-rounded p-button-text hidden margin-2 button-only"></button>
        <button pButton pRipple type="button" icon="pi pi-send" class="p-button-rounded p-button-text hidden margin-2 button-only"></button>
      </div>
    </ng-container>
    <ng-container *ngIf="openSlots > 0">
      <div class="open-slots margin-3">
        <ng-container *ngIf="alreadyJoined">
          <div futuretech class="joined-player leave-match row full-width row-lines">
            <div class="player margin-2"><button pButton pRipple type="button" class="join-match" label="Leave Match" (click)="leave()"></button></div>
          </div>
        </ng-container>
        <div>
          <div class="waiting"><em>Waiting for players...</em></div>
          <div class="slots">{{openSlots}} <span *ngIf="openSlots > 1">slots</span><span *ngIf="openSlots == 1">slot</span> remaining</div>
        </div>
      </div>
    </ng-container>
  </ng-template>
</p-table>
