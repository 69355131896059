import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { GameService } from '../services/game.service';
import { Game } from '../util/game';

// Route resolver that gets the list of games this user can play
@Injectable({
  providedIn: 'root'
})
export class GameListResolver implements Resolve<Game[]> {
  // --------------------------------------------------------------------------
  constructor(
    private gameService: GameService
  ) { }

  // --------------------------------------------------------------------------
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Game[]> {
    return this.gameService.getList();
  }
}
