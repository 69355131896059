<div futuretech class="main-content p-component">
  <div class="main-content-container">
    <div class="standard-container content">
      <div class="content-header">Your Facilitation Events</div>
        <div class="content">
          <ng-container *ngFor="let event of events">
            <div class="event-list-item" (click)="view(event)">
              <h2 class="event-name" >{{event.name}}</h2>
              <p class="event-description" >{{event.description}}</p>
              <div class="side-buttons">
                <ng-container *ngIf="event.isCreator">
                  <p-button pRipple type="button" icon="pi pi-ellipsis-v" class="margin-1" styleClass="p-button-rounded" (onClick)="edit(event); $event.stopPropagation()" ></p-button>
                  <p-button pRipple type="button" icon="pi pi-trash" class="margin-1" styleClass="p-button-rounded" (onClick)="confirmDelete(event); $event.stopPropagation()" ></p-button>
                </ng-container>
              </div>
            </div>
          </ng-container>
          <div class="margin-3">
            <p-button (onClick)="new()">New Event</p-button>
          </div>
        </div>
      <div class="content-footer"></div>
    </div>
  </div>
</div>
<p-confirmDialog header="Confirmation" icon="pi pi-trash"></p-confirmDialog>
