import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { LoadingService } from 'src/app/services/loading.service';
import { MatchService } from 'src/app/services/match.service';
import { GameRole } from 'src/app/util/game';
import { Match } from 'src/app/util/match';
import { Profile } from 'src/app/util/profile';
import {AwsConfigService} from "../../services/aws-config.service";

// UI for displaying the details for one particular match
// The match in question is gathered from the route resolver, so we know
// it exists if we got here
@Component({
  selector: 'match-edit',
  templateUrl: './match-edit.component.html',
  styleUrls: ['../match-detail/match-detail.component.scss'],
  providers: [ConfirmationService]
})
export class MatchEditComponent implements OnInit {
  @Input() match!: Match;
  @Input('event-name') eventName!: string;
  @Input() profiles!: Profile[];
  @Input('current-profile') currentProfile!: Profile;
  @Input() roles!: GameRole[];
  @Input('available-roles') availableRoles!: GameRole[];
  @Input('can-play-match') canPlayMatch!: boolean;
  @Output() onJoin: EventEmitter<string> = new EventEmitter();
  @Output() onLeave: EventEmitter<void> = new EventEmitter();
  @Output() onPlay: EventEmitter<void> = new EventEmitter();
  public editedMatch!: Match;

  // --------------------------------------------------------------------------
  constructor(
    private router: Router,
    private confirmationService: ConfirmationService,
    private loadingService: LoadingService,
    private matchService: MatchService,
    protected configService: AwsConfigService
  ) { }

  // --------------------------------------------------------------------------
  ngOnInit() {
    this.editedMatch = Match.clone(this.match);
  }

  // --------------------------------------------------------------------------
  join(role: string): void {
    this.onJoin.emit(role);
  }

  // --------------------------------------------------------------------------
  leave(): void {
    this.onLeave.emit();
  }

  // --------------------------------------------------------------------------
  changed(a: any, b: any): boolean {
    return a != b;
  }

  // --------------------------------------------------------------------------
  needsSave() : boolean {
    return !!this.match.name && (
      this.editedMatch.name != this.match.name ||
      this.editedMatch.event != this.match.event ||
      this.editedMatch.enabled != this.match.enabled
    );
  }

  // --------------------------------------------------------------------------
  update(): void {
    this.loadingService.loading = true;
    this.matchService.update(this.editedMatch).subscribe({
      next: () => {
        this.loadingService.loading = false;
        this.router.navigateByUrl('/match');
      },
      error: () => {
        this.loadingService.loading = false;
      }
    });
  }

  // --------------------------------------------------------------------------
  duplicate(): void {
    this.matchService.setMatchToDuplicate(this.match);
    this.router.navigate([`match/new/${this.match.game}`]);
  }

  // --------------------------------------------------------------------------
  delete(): void {
    this.confirmationService.confirm({
      header: 'Delete Match?',
      message: `Are you sure that you want to delete this match?`,
      accept: () => {
        // Delete the match and then return to the match list
        this.matchService.delete(this.match).subscribe(() => this.router.navigate(['match']));
      }
    });
  }

  // --------------------------------------------------------------------------
  play(): void {
    this.onPlay.emit();
  }
}
