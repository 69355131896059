import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FacilitationEventService } from 'src/app/services/facilitation-event.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ProfileService } from 'src/app/services/profile.service';
import { FacilitationEvent } from 'src/app/util/facilitation-event';
import { Game } from 'src/app/util/game';
import { Match } from 'src/app/util/match';
import { Profile } from 'src/app/util/profile';

export type FacilitationEventEditorOutput = { event: FacilitationEvent, guestAccounts: Profile[], matches: Match[] };
const usernamePattern: RegExp = /[\p{L}\p{M}\p{Sc}\p{Sk}\p{Sm}\p{N}\p{P}]+/gu;

@Component({
  selector: 'facilitation-event-editor',
  templateUrl: './facilitation-event-editor.component.html',
  styleUrls: ['./facilitation-event-editor.component.scss']
})
export class FacilitationEventEditorComponent implements OnInit, OnDestroy {
  @Input() header!: string;
  @Input('button-label') buttonLabel!: string;
  @Input('current-profile') currentProfile!: Profile;
  @Input() event!: FacilitationEvent;
  @Input() events!: FacilitationEvent[];
  @Input() games!: Game[];
  @Input('guest-accounts') guestAccounts!: Profile[];
  @Input() matches!: Match[];
  @Input() profiles!: Profile[];
  @Output() onApplyChanges: EventEmitter<FacilitationEventEditorOutput> = new EventEmitter<FacilitationEventEditorOutput>();
  public potentialProfiles!: Profile[];
  public disabledReason?: string;
  public nameChanged!: boolean;
  public descriptionChanged!: boolean;
  public guestAccountsChanged!: boolean;
  public matchesChanged!: boolean;
  private originalEvent!: FacilitationEvent;
  private originalGuestAccounts!: Profile[];
  private originalMatches!: Match[];

  // --------------------------------------------------------------------------
  constructor(
    private router: Router,
    private facilitationEventService: FacilitationEventService,
    private loadingService: LoadingService,
    private profileService: ProfileService,
  ) { }

  // --------------------------------------------------------------------------
  ngOnInit(): void {
    this.originalEvent = FacilitationEvent.clone(this.event);
    this.originalGuestAccounts = this.guestAccounts.map(account => Profile.clone(account));
    this.originalMatches = this.matches.map(match => Match.clone(match));
    this.potentialProfiles = this.profiles.concat(this.guestAccounts);
    this.updateDisabledReason();

    this.facilitationEventService.addListListener(this.onEventListUpdated);
    this.profileService.addListListener(this.onProfilesUpdated);
  }

  // --------------------------------------------------------------------------
  ngOnDestroy(): void {
    this.facilitationEventService.removeListListener(this.onEventListUpdated);
    this.profileService.removeListListener(this.onProfilesUpdated);
  }

  // --------------------------------------------------------------------------
  onEventNameChanged(): void {
    this.nameChanged = this.originalEvent.name != this.event.name;
    this.updateDisabledReason();
  }

  // --------------------------------------------------------------------------
  onEventDescriptionChanged(): void {
    this.descriptionChanged = (!!this.originalEvent.description || !!this.event.description) && this.originalEvent.description != this.event.description;
  }

  // --------------------------------------------------------------------------
  onGuestAccountsChanged(): void {
    // Update whether our guest accounts have changed or not
    this.guestAccountsChanged = this.originalGuestAccounts.length != this.guestAccounts.length ||
      this.originalGuestAccounts.some(originalAccount => !this.guestAccounts.some(account => originalAccount.equals(account)));

    this.potentialProfiles = this.profiles.concat(this.guestAccounts);
    this.updateDisabledReason();
  }

  // --------------------------------------------------------------------------
  onMatchesChanged(): void {
    // Update whether our matches have changed or not
    this.matchesChanged = this.originalMatches.length != this.matches.length ||
      this.originalMatches.some(originalMatch => !this.matches.some(match => originalMatch.equals(match)));

    this.updateDisabledReason();
  }

  // --------------------------------------------------------------------------
  updateDisabledReason(): void {
    // Don't bother checking a reason we can't create/update an event while it's
    // processing a previous operation
    if (!this.loadingService.loading) {
      if (!this.event.name) {
        this.disabledReason = 'Event needs a name';
      }
      else if (!this.event.name.match(usernamePattern)) {
        this.disabledReason = 'Event name has invalid characters';
      }
      else if (!this.facilitationEventService.isUniqueName(this.event)) {
        this.disabledReason = 'Events must have unique names';
      }
      else if (!this.areGuestAccountNamesUnique()) {
        this.disabledReason = 'All guest accounts need unique display names';
      }
      else if (!this.areGuestAccountNamesValid()) {
        this.disabledReason = 'Guest account has invalid characters in it';
      }
      else if (!this.areGuestAccountUsernamesValid()) {
        this.disabledReason = 'Event name and guest display name combined lengths must have 127 or less characters';
      }
      else if (!this.areMatchesValid()) {
        this.disabledReason = 'All matches must have all player slots filled';
      }
      else {
        this.disabledReason = undefined;
      }
    }
  }

  // --------------------------------------------------------------------------
  canCreate(): boolean {
    // We can create/update if there isn't a disabled reason and we actually have
    // changes to apply
    return !this.disabledReason && (this.nameChanged || this.descriptionChanged ||
      this.guestAccountsChanged || this.matchesChanged);
  }

  // --------------------------------------------------------------------------
  create(): void {
    this.onApplyChanges.emit({ event: this.event, guestAccounts: this.guestAccounts, matches: this.matches });
  }

  // --------------------------------------------------------------------------
  private areGuestAccountNamesUnique(): boolean {
    // Are all guest account names unique?
    return this.guestAccounts.every(account => !this.guestAccounts.some(otherAccount => otherAccount != account && otherAccount.displayName == account.displayName));
  }

  // --------------------------------------------------------------------------
  private areGuestAccountNamesValid(): boolean {
    // All guest accounts have to have valid characters
    return this.guestAccounts.every(account => account.displayName.match(usernamePattern));
  }

  // --------------------------------------------------------------------------
  private areGuestAccountUsernamesValid(): boolean {
    // All guest accounts have to generate usernames with 128 or less characters
    return this.guestAccounts.every(account => this.event.getGuestUsername(account).length <= 128);
  }

  // --------------------------------------------------------------------------
  private areMatchesValid(): boolean {
    // All guest accounts have to have a unique, non-empty name
    return this.matches.every(match => Object.keys(match.roleUserMapping).length == match.playerCount);
  }

  // --------------------------------------------------------------------------
  private onEventListUpdated = (events: FacilitationEvent[]): void => {
    // If this is our event that got deleted, then re-route the user to the event list
    if (!!this.event.id && !events.some(event => event.id == this.event.id)) {
      this.router.navigate(['/event']);
    }
    else {
      this.events = events;
      this.updateDisabledReason();
    }
  }

  // --------------------------------------------------------------------------
  private onProfilesUpdated = (profiles: Profile[]): void => {
    // Go through and update or add any profiles in the incoming list
    profiles.forEach(updatedProfile => {
      if (!!this.event.id && updatedProfile.event == this.event.id) {
        let foundProfile = this.guestAccounts.find(profile => updatedProfile.sub == profile.sub);
        if (!!foundProfile) {
          foundProfile.displayName = updatedProfile.displayName;
        }
        else {
          this.guestAccounts.push(Profile.clone(updatedProfile));
        }
      }
    });

    // Run through and remove profiles from our list that aren't in the updated list
    this.guestAccounts = this.guestAccounts.filter(oldProfile => profiles.some(newProfile => oldProfile.sub == newProfile.sub));

    // Update our profile list
    this.potentialProfiles = profiles.filter(profile => !profile.event).concat(this.guestAccounts);
  }
}
