<div class="mini-match-new standard-container expand with-border">
  <form action="">
    <div class="content margin-3">
      <div futuretech field class="dropDown row full-width row-lines">
        <label for="game">Game</label>
        <p-dropdown name="game" id="game" [options]="games" [(ngModel)]="selectedGame" (ngModelChange)="onSelectedGameChanged()" optionLabel="name"></p-dropdown>
      </div>

      <div futuretech field class="inputText row full-width row-lines">
        <label for="matchName">Match Name</label>
        <input pInputText autofocus type="text" name="matchName" id="matchName" [(ngModel)]="match.name" (ngModelChange)="updateDisabledReason()"/>
      </div>

      <div futuretech field class="switch row full-width row-lines">
        <label for="active">Enabled</label>
        <p-inputSwitch name="active" id="active" [(ngModel)]="match.enabled" label="-" title="Enable/Disable this match"></p-inputSwitch>
      </div>

      <div futuretech field class="inputNumber row full-width row-lines" *ngIf="!!selectedGame">
        <label for="playerCount">Number of Players</label>
        <p-inputNumber name="playerCount" id="playerCount" [(ngModel)]="match.playerCount" [showButtons]="true" buttonLayout="horizontal" inputId="horizontal" spinnerMode="horizontal" [step]="1"
          incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" mode="decimal" [min]="selectedGame.minPlayers" [max]="selectedGame.maxPlayers">
        </p-inputNumber>
      </div>

      <ng-container *ngIf="!!selectedGame">
        <add-player mini-match-list [event-id]="event.id" [profiles]="profiles" [roles]="selectedGame.roles" [player-count]="match.playerCount" [role-user-mapping]="match.roleUserMapping" (onMapChange)="onRoleMappingChanged($event)"></add-player>
      </ng-container>
    </div>

    <div class="content-footer">
      <p-button class="margin-1" (onClick)="createMatch()" [disabled]="!!disabledReason">{{buttonLabel}}</p-button>
      <p-button class="margin-1" (onClick)="cancel()">Cancel</p-button>
    </div>

    <div class="content-message"><span class="disabled-reason" *ngIf="disabledReason"><i class="pi pi-exclamation-triangle margin-2-horizontal"></i>{{disabledReason}}<i class="pi pi-exclamation-triangle margin-2-horizontal"></i></span></div>
  </form>
</div>
