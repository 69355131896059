import { Component, EventEmitter, Input, Output } from '@angular/core';

// UI for getting the information needed for logging in a registered user
@Component({
  selector: 'loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {
  @Input('active') active!: boolean;

  // --------------------------------------------------------------------------
  constructor(
  ) {}

}
