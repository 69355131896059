<div futuretech class="main-content p-component">
  <div class="main-content-container">
    <div class="standard-container content">
      <div class="content-header"
           [class]="{'column-filter-applied': columnFilter != ''}">

        <div *ngIf="currentProfile.isGlobalECCOMember()">
          <span *ngIf="columnFilter != ''">Showing Matches for <em>{{ columnFilter }}</em></span><span

            *ngIf="columnFilter == ''">Your Matches</span>
          <button pButton label="Show All Matches" class="p-button-outlined clear-search-button"
                  *ngIf="columnFilter != ''" icon="pi pi-filter-slash" (click)="clearAll()"></button>
        </div>
        <div *ngIf="!currentProfile.isGlobalECCOMember()">
          <span *ngIf="columnFilter != ''">Showing Matches for <em>{{ columnFilter }}</em></span><span

            *ngIf="columnFilter == ''">Your Events & Matches</span>
          <button pButton label="Show All Matches" class="p-button-outlined clear-search-button"
                  *ngIf="columnFilter != ''" icon="pi pi-filter-slash" (click)="clearAll()"></button>
        </div>
      </div>

      <div class="content no-footer">
        <div class="search-tools">
          <div class="flex">
            <span class="search-box p-input-icon-left ml-auto">
              <i class="pi pi-search"></i>
              <input pInputText type="text" [(ngModel)]="searchFilter"
                     (ngModelChange)="applyFilterGlobal($event, 'custom-contains')" placeholder="Search keyword"/>
            </span>
            <button pButton label="Clear Search Results" class="p-button-outlined clear-search-button"
                    [disabled]="searchFilter == ''" [class]="{'offset-clear-search-button': searchFilter == ''}"
                    icon="pi pi-filter-slash" (click)="clear()"></button>
          </div>
        </div>

        <div class="tables">
          <p-table futuretech event-matches #eventMatches
                   [value]="matches"
                   [globalFilterFields]="['game','name','event']"
                   [customSort]="true"
                   (sortFunction)="sortByCreationDates($event)"
                   dataKey="event"
                   rowGroupMode="subheader"
                   *ngIf="!currentProfile.isGlobalECCOMember()"
                   groupRowsBy="event">

            <ng-template pTemplate="caption">
              <div class="table-title">
                <span class="header-pos-adjust">Events</span>
                <!-- if user is ecco member, then we do not support facilitation events or guest users -->
                <ng-container *ngIf="!currentProfile.isGuest()">
                  <p-button class="header-pos-adjust new-event-button wide margin-2-horizontal" (onClick)="newEvent()"
                            icon="pi pi-plus" title="Create a new event" styleClass="p-button-rounded"></p-button>
                  <p-overlayPanel #op [style]="{'width': '450px'}">
                    <div>An Event is a grouping of matches that must be pre-assigned. Guest accounts may be created as
                      part of an event.
                    </div>
                  </p-overlayPanel>
                  <p-button class="header-pos-adjust new-event-button wide margin-2-horizontal small"
                            (click)="op.toggle($event)" icon="pi pi-question" title="What's an event?"
                            styleClass="p-button-rounded"></p-button>
                </ng-container>
              </div>
            </ng-template>

            <ng-template pTemplate="groupheader" let-match let-rowIndex="rowIndex" let-expanded="expanded">
              <ng-container *ngIf="!match.event || !!eventsById[match.event]">
                <tr class="row-group-header button event-header" [pRowToggler]="match"
                    [class]="{'open-match': !match.event, 'empty-match': !match.id}" *ngIf="match.event">
                  <td colspan="10">
                    <button type="button" pButton pRipple class="p-button-text p-button-rounded p-button-plain mr-2"
                            [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                    <span class="margin-2-horizontal vertical-align-super" [class]="{'display-none': !match.event}"><i
                        class="pi pi-calendar"></i></span>
                    <span class="event-name ml-2 vertical-align-super"
                          [class]="{'open-match': !match.event}">{{ eventsById[match.event].name }}</span>
                    <span class="match-count ml-2" [class]="{'open-match': !match.event, 'empty-match': !match.id }"
                          [class]="{'hide': searchFilter != '' || columnFilter != ''}"
                          title="This event has {{matchCount[match.event]}} matches">{{ matchCount[match.event] }}</span>
                    <div class="event-description" *ngIf="match.event">{{ eventsById[match.event].description }}</div>
                    <ng-container *ngIf="match.event && eventsById[match.event].isCreator">
                      <div class="creator-buttons">
                        <p-button pRipple type="button" styleClass="p-button-rounded" icon="pi pi-pencil"
                                  class="margin-1"
                                  (onClick)="editEvent(eventsById[match.event]); $event.stopPropagation()"></p-button>
                        <p-button pRipple type="button" styleClass="p-button-rounded" icon="pi pi-trash"
                                  class="margin-1"
                                  (onClick)="confirmDeleteEvent(eventsById[match.event]); $event.stopPropagation()"></p-button>
                      </div>
                    </ng-container>
                  </td>
                </tr>
              </ng-container>
            </ng-template>

            <ng-template pTemplate="groupfooter" let-match>
              <tr *ngIf="!match.id">
                <td colspan="10" class="empty-event-root text-right font-bold pr-6">
                  <div class="empty-event">No matches for this event</div>
                </td>
              </tr>
            </ng-template>

            <ng-template pTemplate="header">
              <ng-container *ngIf="!noEvents">
                <tr class="text-center">
                  <th style="width: 2em" class="tab"></th>
                  <th style="width: 8em" class="play-game-button"></th>
                  <th style="width: 5em" class="icon"></th>
                  <th pSortableColumn="game" style="width: 12em" class="text-left">Game
                    <p-sortIcon field="game"></p-sortIcon>
                    <button pButton class="clear-filter p-button-rounded p-button-text" icon="pi pi-refresh"
                            (click)="clearEventSort(); $event.stopPropagation()"></button>
                  </th>
                  <th pSortableColumn="name">Match Name
                    <p-sortIcon field="name"></p-sortIcon>
                    <button pButton class="clear-filter p-button-rounded p-button-text" icon="pi pi-refresh"
                            (click)="clearEventSort(); $event.stopPropagation()"></button>
                  </th>
                  <th pSortableColumn="status" class="status">Status
                    <p-sortIcon field="status"></p-sortIcon>
                    <button pButton class="clear-filter p-button-rounded p-button-text" icon="pi pi-refresh"
                            (click)="clearEventSort(); $event.stopPropagation()"></button>
                  </th>
                  <th pSortableColumn="playerCountSortCount" style="width: 9em">Players
                    <p-sortIcon field="playerCountSortCount"></p-sortIcon>
                    <button pButton class="clear-filter p-button-rounded p-button-text" icon="pi pi-refresh"
                            (click)="clearEventSort(); $event.stopPropagation()"></button>
                  </th>
                  <th style="width: 5em" class="has-joined">Joined</th>
                  <th class="delete">Delete</th>
                </tr>
                <tr class="display-none">
                  <th>
                    <!--suppress XmlDuplicatedId -->
                    <p-columnFilter id="EventFilterRootElement" #EventFilterRootElement field="event"
                                    [showMenu]="false">
                      <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                        <!--suppress XmlDuplicatedId -->
                        <input id="EventFilterElement"
                               #EventFilterElement
                               type="text"
                               pInputText
                               [ngModel]="value"
                               (ngModelChange)="applyFilter('event', $event)"
                               class="p-inputtext"
                               placeholder="">
                      </ng-template>
                    </p-columnFilter>
                  </th>
                  <th class="status" style="width: 4em"></th>
                  <th>
                    <p-columnFilter #GameFilterRootElement field="game" [showMenu]="false">
                      <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                        <!--suppress XmlDuplicatedId -->
                        <input id="GameFilterElement"
                               #GameFilterElement
                               type="text"
                               pInputText
                               [ngModel]="value"
                               (ngModelChange)="applyFilter('game', $event)"
                               class="p-inputtext"
                               placeholder="">
                      </ng-template>
                    </p-columnFilter>
                  </th>
                  <th>
                    <p-columnFilter #MatchFilterRootElement field="name" [showMenu]="false">
                      <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                        <!--suppress XmlDuplicatedId -->
                        <input id="MatchFilterElement"
                               #MatchFilterElement
                               type="text"
                               pInputText
                               [ngModel]="value"
                               (ngModelChange)="applyFilter('name', $event)"
                               class="p-inputtext"
                               placeholder="">
                      </ng-template>
                    </p-columnFilter>
                  </th>
                  <th class="playerCount" style="width: 7em"></th>
                  <th class="details"></th>
                  <th class="delete"></th>
                </tr>
              </ng-container>
            </ng-template>

            <ng-template pTemplate="rowexpansion" let-match let-rowIndex="rowIndex">
              <tr (click)="select(match)" class="cursor-pointer text-center hover event-match"
                  [class]="{'not-enabled': !match.enabled}" *ngIf="!!match.id">
                <td style="width: 2em" class="tab"></td>
                <td class="play-game-button">
                  <p-button
                      class="margin-1"
                      [disabled]="!canPlay(match)"
                      (onClick)="play(match); $event.stopPropagation()">
                    <span [class]="{'display-none': !match.isFinished(currentProfile.sub)}">View</span>
                    <span [class]="{'display-none': match.isFinished(currentProfile.sub)}">Play</span>
                  </p-button>
                </td>
                <td class="icon"><img src="{{gamesById[match.game].thumbnail}}" alt=""></td>
                <td class="text-left">{{ gamesById[match.game].name }}</td>
                <td class="match-name">{{ match.name }}</td>
                <td class="status">{{ match.status }}</td>
                <td class="match-player-count" [class]="{'match-full': isMatchFull(match)}">
                  <span>{{ getPlayerJoinedCountAsString(match) }}</span>
                </td>
                <td class="has-joined"><i class="pi pi-user" *ngIf="hasJoined(match)"></i></td>
                <td class="delete">
                  <ng-container *ngIf="match.isCreator">
                    <span class="button delete" (click)="confirmDeleteMatch(match); $event.stopPropagation()"><i
                        class="pi pi-trash"></i></span>
                  </ng-container>
                </td>
              </tr>
            </ng-template>

            <ng-template pTemplate="footer">
              <ng-container *ngIf="noEvents">
                <tr col-span="8">
                  <td class="empty">No Events</td>
                </tr>
              </ng-container>
            </ng-template>

          </p-table>

          <!-- open matches -->
          <ng-container *ngIf="!currentProfile.isGuest()">
            <p-table futuretech public-matches
                     [value]="matches" #publicMatches

                     [globalFilterFields]="['game','name','event']"

                     sortField="creationDate"
            >
              <ng-template pTemplate="caption">
                <div class="table-title">
                  <span class="header-pos-adjust" *ngIf="!configService.isECCODeployment()">Community Matches</span>
                  <span class="header-pos-adjust" *ngIf="configService.isECCODeployment()">Matches</span>
                  <p-button class="header-pos-adjust new-event-button wide margin-2-horizontal"
                            (onClick)="goToGameLibrary()" icon="pi pi-plus"
                            title="Go to the Game Library to create a new match"
                            styleClass="p-button-rounded"></p-button>
                  <p-overlayPanel #op [style]="{'width': '450px'}">
                    <div>Slots in an Open Match do not need to be pre-assigned like Event matches. Community Matches can only
                      be played by users with non-guest accounts.
                    </div>
                  </p-overlayPanel>
                  <p-button class="header-pos-adjust new-event-button wide margin-2-horizontal small"
                            (click)="op.toggle($event)" icon="pi pi-question" title="What's an open match?"
                            styleClass="p-button-rounded"></p-button>
                </div>
              </ng-template>

              <ng-template pTemplate="header" let-match>
                <ng-container *ngIf="!noPublicMatches">
                  <tr class="text-center">
                    <th style="width: 8em" class="play-game-button"></th>
                    <th style="width: 5em" class="icon"></th>
                    <th pSortableColumn="game" style="width: 12em" class="text-left">Game
                      <p-sortIcon field="game"></p-sortIcon>
                      <button pButton class="clear-filter p-button-rounded p-button-text" icon="pi pi-refresh"
                              (click)="clearPublicSort(); $event.stopPropagation()"></button>
                    </th>
                    <th pSortableColumn="name">Match Name
                      <p-sortIcon field="name"></p-sortIcon>
                      <button pButton class="clear-filter p-button-rounded p-button-text" icon="pi pi-refresh"
                              (click)="clearPublicSort(); $event.stopPropagation()"></button>
                    </th>
                    <th pSortableColumn="status" class="status">Status
                      <p-sortIcon field="status"></p-sortIcon>
                      <button pButton class="clear-filter p-button-rounded p-button-text" icon="pi pi-refresh"
                              (click)="clearPublicSort(); $event.stopPropagation()"></button>
                    </th>
                    <th pSortableColumn="playerCountSortCount" style="width: 9em">Players
                      <p-sortIcon field="playerCountSortCount"></p-sortIcon>
                      <button pButton class="clear-filter p-button-rounded p-button-text" icon="pi pi-refresh"
                              (click)="clearPublicSort(); $event.stopPropagation()"></button>
                    </th>
                    <th style="width: 5em" class="has-joined">Joined</th>
                    <th class="delete">Delete</th>
                  </tr>
                  <tr class="display-none">
                    <th>
                      <!--suppress XmlDuplicatedId -->
                      <p-columnFilter id="EventFilterRootElement" #EventFilterRootElement field="event"
                                      [showMenu]="false">
                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                          <!--suppress XmlDuplicatedId -->
                          <input id="EventFilterElement"
                                 #EventFilterElement
                                 type="text"
                                 pInputText
                                 [ngModel]="value"
                                 (ngModelChange)="applyFilter('event', $event)"
                                 class="p-inputtext"
                                 placeholder="">
                        </ng-template>
                      </p-columnFilter>
                    </th>
                    <th class="status"></th>
                    <th>
                      <p-columnFilter #GameFilterRootElement field="game" [showMenu]="false">
                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                          <!--suppress XmlDuplicatedId -->
                          <input id="GameFilterElement"
                                 #GameFilterElement
                                 type="text"
                                 pInputText
                                 [ngModel]="value"
                                 (ngModelChange)="applyFilter('game', $event)"
                                 class="p-inputtext"
                                 placeholder="">
                        </ng-template>
                      </p-columnFilter>
                    </th>
                    <th>
                      <p-columnFilter #MatchFilterRootElement field="name" [showMenu]="false">
                        <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                          <!--suppress XmlDuplicatedId -->
                          <input id="MatchFilterElement"
                                 #MatchFilterElement
                                 type="text"
                                 pInputText
                                 [ngModel]="value"
                                 (ngModelChange)="applyFilter('name', $event)"
                                 class="p-inputtext"
                                 placeholder="">
                        </ng-template>
                      </p-columnFilter>
                    </th>
                    <th class="playerCount" style="width: 7em"></th>
                    <th class="active" style="width: 7em"></th>
                    <th class="details"></th>
                    <th class="delete"></th>
                  </tr>
                </ng-container>
              </ng-template>

              <ng-template pTemplate="body" let-match>
                <tr class="cursor-pointer text-center hover" *ngIf="!match.event"
                    [class]="{'not-enabled': !match.enabled}" (click)="select(match)">
                  <td class="play-game-button">
                    <p-button
                        class="margin-1"
                        [disabled]="!canPlay(match)"
                        (onClick)="play(match); $event.stopPropagation()">
                      <span [class]="{'display-none': !match.isFinished(currentProfile.sub)}">View</span>
                      <span [class]="{'display-none': match.isFinished(currentProfile.sub)}">Play</span>
                    </p-button>
                  </td>
                  <td class="icon"><img src="{{gamesById[match.game].thumbnail}}" alt=""></td>
                  <td class="text-left">{{ gamesById[match.game].name }}</td>
                  <td class="match-name">{{ match.name }}</td>
                  <td class="status">{{ match.status }}</td>
                  <td class="match-player-count" [class]="{'match-full': isMatchFull(match)}">
                    <span>{{ getPlayerJoinedCountAsString(match) }}</span>
                  </td>
                  <td class="has-joined"><i class="pi pi-user" *ngIf="hasJoined(match)"></i></td>
                  <td class="delete">
                    <ng-container *ngIf="match.isCreator">
                      <span class="button delete" (click)="confirmDeleteMatch(match); $event.stopPropagation()"><i
                          class="pi pi-trash"></i></span>
                    </ng-container>
                  </td>
                </tr>
              </ng-template>

              <ng-template pTemplate="footer">
                <ng-container *ngIf="noPublicMatches">
                  <tr col-span="8">
                    <td class="empty">No Matches</td>
                  </tr>
                </ng-container>
              </ng-template>
            </p-table>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<p-confirmDialog header="Confirmation" icon="pi pi-trash"></p-confirmDialog>
