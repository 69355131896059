export enum ProfileType {
  You = 'You',
  GlobalECCO = 'Global ECCO Account',
  Guest = 'Guest'
}

// Data structure to wrap what a Profile is
// A profile is a way to try to humanize a user
// with some human understandable things like
// display names and icons
export class Profile {
  public displayName!: string;
  public email!: string;
  public iconURL?: string;
  public sub!: string; //The unique user ID generated by AWS UserPool
  public type: ProfileType = ProfileType.GlobalECCO;
  public event?: string;
  public groups!: string[];

  // --------------------------------------------------------------------------
  static clone(other: Profile): Profile {
    let clone = new Profile();
    clone.displayName = other.displayName;
    clone.email = other.email;
    clone.iconURL = other.iconURL;
    clone.sub = other.sub;
    clone.type = other.type;
    clone.event = other.event;
    clone.groups = other.groups.map(it => it);
    return clone;
  }

  // --------------------------------------------------------------------------
  isGuest(): boolean {
    return !!this.event || this.type == ProfileType.Guest;
  }

  // --------------------------------------------------------------------------
  isMemberOfGroup(group: string): boolean {
    return this.groups.includes(group)
  }

  // --------------------------------------------------------------------------
  isGlobalECCOMember(): boolean {
    return this.groups.includes("ge-members") ||
      this.groups.includes("ge-facilitator")
  }

  // --------------------------------------------------------------------------
  equals(other: Profile): boolean {
    return this.displayName == other.displayName &&
      this.email == other.email &&
      this.iconURL == other.iconURL &&
      this.sub == other.sub &&
      this.type == other.type &&
      this.event == other.event &&
      this.groups.every(it => other.groups.includes(it)) &&
      other.groups.every(it => this.groups.includes(it))
  }
}
